import styled from 'styled-components';
import { ThemeType } from 'types';
import TitleWrapper from 'components/TitleWrapper/styles';

export default styled.section(
  ({
    theme: {
      colors,
      mobileView,
      utils: { rgba },
    },
  }: ThemeType) => `
    ${TitleWrapper} {
      margin-bottom: 36px;
    }
`,
);
