import React from 'react';
import Title from 'components/Title';
import { TicketType } from 'types';
import { StyledTicket, StyledCurrencyFlag } from './styles';
import Cta from 'components/Cta';

export const Ticket: React.FunctionComponent<TicketType> = ({
  title,
  ticketDetails: { soldOut, termsConditionsLink, conditions, description, price, ticketLinks },
}) => (
  <StyledTicket className={soldOut ? 'sold-out' : ''}>
    <Title size="small">
      <span dangerouslySetInnerHTML={{ __html: title }} />
      <span className="p4 ticket-price">{price}</span>
      <span className="p6 ticket-price">{conditions}</span>
    </Title>
    <div className="ticket-content">
      
      <div className="p4 ticket-description" dangerouslySetInnerHTML={{ __html: description ? description : '' }} />
      <div className="tickets-wrapper">
      {ticketLinks && ticketLinks.map((ticket, index) => {
        if(ticket?.link?.url){
          return <Cta key={`ticket-link-${index}`} title={ticket?.link?.title ? ticket.link.title : "Buy Tickets" } url={ticket?.link?.url ? ticket.link.url : ''} target={ticket?.link?.target ? ticket.link.target : "_blank"} color="pink" inverted={true}><StyledCurrencyFlag currency={ticket.currency} /></Cta>
        }else{
          return null
        }
      })}
      </div>
      {termsConditionsLink ? (
        <a className="p4 ticket-terms_url" href={termsConditionsLink}>
          Terms and Conditions
        </a>
      ) : null}
    </div>
  </StyledTicket>
);

export default Ticket;
