import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const TikTokIcon: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 512 512" {...props}>
    <path d="M464.73 112.46c-53.74 0-97.46-43.72-97.46-97.46 0-8.29-6.71-15-15-15h-80.34c-8.28 0-15 6.71-15 15v329.37c0 31.59-25.71 57.3-57.3 57.3s-57.3-25.7-57.3-57.3c0-31.6 25.7-57.3 57.3-57.3 8.29 0 15-6.71 15-15v-80.34c0-8.28-6.71-15-15-15C107.2 176.73 32 251.93 32 344.37S107.2 512 199.63 512s167.64-75.2 167.64-167.63v-145.8c29.85 15.92 63.07 24.23 97.46 24.23 8.29 0 15-6.71 15-15v-80.34c0-8.28-6.71-15-15-15z"/>
  </StyledSvg>
);

export default TikTokIcon;