import styled from 'styled-components';
import { ThemeType } from 'types';

interface Props {
  size: string;
}

const Title = styled.h2(
  ({ theme: { mobileView, fontSizes, fontFamilies, colors }, size }: Props & ThemeType) => `
    color: inherit;
    text-decoration-color: ${colors.white};
    text-transform: uppercase;

    ${
      size === 'large'
        ? `
      line-height: 1.05;
      font-size: ${fontSizes.xl};
      letter-spacing: -0.22px;
      font-family: ${fontFamilies.heading};
      font-weight: 400;
    `
        : ''
    }

    ${
      size === 'medium'
        ? `
      line-height: 1.1;
      font-size: ${fontSizes.lg};
      letter-spacing: 1.43px;
      font-family: ${fontFamilies.default};
    `
        : ''
    }

    ${
      size === 'small'
        ? `
      line-height: 1.1;
      font-size: ${fontSizes.sm};
      letter-spacing: 0.95px;
      font-family: ${fontFamilies.heading};
    `
        : ``
    }

    ${mobileView} {
      ${
        size === 'large'
          ? `
        font-size: 38px;
        letter-spacing: -0.22px;
        font-family: ${fontFamilies.heading};
      `
          : ''
      }
  
      ${
        size === 'medium'
          ? `
        line-height: 1.1;
        letter-spacing: 1.43px;
        font-family: ${fontFamilies.heading};
      `
          : ''
      }
  
      ${
        size === 'small'
          ? `
        line-height: 1.1;
        font-size: ${fontSizes.sm};
        letter-spacing: 0.95px;
        font-family: ${fontFamilies.heading};
      `
          : ``
      }
    }
  `,
);

export default Title;
