import styled from 'styled-components';


export const StyledList = styled.ul(({theme: {colors}}) => {
    return `
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: ${colors.pink};
    margin: 0;
    padding: 0;
    min-width: 100%;
    li {
        a {
            display: block;
            padding: 0.5em;
            &:hover {
                background: ${colors.white};
            }
        }
    }
`});

export const StyledLocationSelector = styled.div(({theme: {colors}}) => {
    return `
    position: relative;
    margin: 0 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${colors.pink};
    font-weight: 900;
    font-size: 13px;
    letter-spacing: 0.67px;
    text-transform: uppercase;
    padding: 0.5em 1.5em 0.5em 0.5em;
    line-height: 1.14;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M23.468 2.984a2 2 0 00-1.742-1.018H2.274A2 2 0 00.563 5l9.726 16.07a2 2 0 003.422 0L23.437 5a2 2 0 00.031-2.016z'/%3E%3C/svg%3E");
    background-position: center right 0.25em;
    background-repeat: no-repeat;
    background-size: 0.75em;
    ${StyledList} {
        display: none;
    }
    &:hover {
        ${StyledList} {
            display: block;
        }   
    }
`});
