import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const PaperPlaneIcon: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 24 22" {...props}>
    <path d="M23.82.12A.5.5,0,0,0,23.31,0l-23,9.5a.49.49,0,0,0-.27.65.48.48,0,0,0,.28.28l6.33,2.45a.52.52,0,0,0,.47-.05l8.4-6a.5.5,0,0,1,.7.12.49.49,0,0,1-.07.65l-7,6.75a.51.51,0,0,0-.15.36V21.5a.5.5,0,0,0,.93.25l3.17-5.42a.26.26,0,0,1,.34-.1l5.82,3.2a.49.49,0,0,0,.68-.19.34.34,0,0,0,0-.14L24,.6A.48.48,0,0,0,23.82.12Z" />
  </StyledSvg>
);

export default PaperPlaneIcon;
