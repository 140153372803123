import styled from 'styled-components';
import { ThemeType } from 'types';
import { StyledBlockInner } from 'components/Block/styles';
import Plant from 'assets/images/red-fill-pandanas.png';
import Title from 'components/Title/styles';
import Video from 'components/Video/styles';

export default styled.section(
  ({
    theme: {
      colors,
      mobileView,
      spacing,
      utils: { rgba },
    },
  }: ThemeType) => `
  margin-bottom: ${spacing.x8};
  ${mobileView} {
    margin-bottom: 100px;
  }

  ${StyledBlockInner} {
    max-width: 952px;
  }

  ${Title} {
    margin-bottom: 31px;
  }

  ${Video} {
    box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};

    &:after {
      content: '';
      position: absolute;
      bottom: -108px;
      right: -102px;
      background-image: url(${Plant});
      background-size: contain;
      background-repeat: no-repeat;
      width: 230px;
      height: 235px;
      z-index: 10;
      pointer-events: none;

      ${mobileView} {
        width: 148px;
        height: 150px;
        bottom: -79px;
        right: -54px;
      }
    }
  }
`,
);
