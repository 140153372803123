import styled from 'styled-components';

export default styled.a`
  svg {
    transition: transform 0.2s cubic-bezier(0.76, 0.12, 0.12, 1.28);
  }

  &:hover svg {
    transform: scale(1.15);
  }
`;
