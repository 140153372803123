import styled from 'styled-components';
import { ThemeType } from 'types';

export default styled.div(
  ({ theme: { fontSizes, fontFamilies } }: ThemeType) => `
    font-size: ${fontSizes.xs};
    font-family: ${fontFamilies.default};
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.04px;
    h1, h2, h3, h4, h5, h6 {
      margin-top: 0.5em;
    }
    h1, h2, h3 {
      font-family: ${fontFamilies.heading};
    }
    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
`,
);
