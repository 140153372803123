import React from 'react';
import { LineupType } from 'types';
import LineupWrapper, { ArtistLink, ScrollingArtistLink, ArtistWrapper } from './styles';
import isBrowser from 'utils/isBrowser';
const numberOfTopBilledActs = 4;

const Lineup: React.FunctionComponent<LineupType> = ({ artists, align = 'center', containerId, artistOffset, onArtistClick, getBaseUrl }) => (
  <LineupWrapper align={align}>
    <div>
      {artists &&
        artists.map(({ id: Wpid, title, slug }, index) => (
          <ArtistWrapper key={index} id={`Link-${Wpid}`}>
            {!containerId && <ArtistLink href={`${slug}`} dangerouslySetInnerHTML={{ __html: title }} />}
            {containerId && (
              <ScrollingArtistLink
                to={`Artist-${Wpid}`}
                smooth={true}
                offset={artistOffset}
                duration={500}
                onClick={() => {
                  if (onArtistClick) {
                    onArtistClick();
                  }
                  if(isBrowser()) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                     'event': 'artistClick',
                     'artist': title
                     });
                   }
                  window.history.pushState(null, '', `${getBaseUrl()}${slug}`);
                }}
                containerId={containerId}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
          </ArtistWrapper>
        ))}
    </div>
  </LineupWrapper>
);

export default Lineup;
