import styled from 'styled-components';
import { HorizontalAlignmentType, ThemeType } from 'types';
import { StyledBlockInner } from 'components/Block/styles';
import Plant from 'assets/images/red-fill-pandanas.png';
import Man from 'assets/images/blue-man-reclining.png';
import StyledCta from 'components/Cta/styles';
import Title from 'components/Title/styles';

interface Props extends ThemeType {
  alignment?: HorizontalAlignmentType;
  postType?: string;
  hideAssets?: boolean;
}

export default styled.section(
  ({ theme: { mobileView, spacing }, alignment, hideAssets, postType }: Props) => `

    ${StyledBlockInner} {
      max-width: 1075px;
      display: flex;
      flex-direction: row${alignment === 'right' ? '-reverse' : ''};
      align-items: center;
      ${!hideAssets && `
        &:before {
          content: '';
          position: absolute;
          bottom: -79px;
          left: -187px;
          background-image: url(${Man});
          background-size: contain;
          background-repeat: no-repeat;
          width: 237px;
          height: 152px;
          z-index: 10;
          pointer-events: none;

          ${mobileView} {
            transform: scaleX(-1);
            width: 178px;
            height: 116px;
            left: auto;
            right: -5px;
            bottom: -49px;
          }
        }
      `}

      a {
        text-decoration: none !important;
        direction: ltr;
      }

      ${mobileView} {
        flex-direction: column;
        align-items: flex-start;
      }

      ${Title} {
        margin-bottom: ${spacing.x2};
      }
    }

    ${StyledCta} {
      margin-top: 30px;
    }
`,
);

export const Media = styled.section(
  ({
    theme: {
      colors,
      mobileView,
      spacing,
      utils: { rgba },
    },
    postType,
    hideAssets
  }) => `
  width: ${(postType !== 'partner') ? `52.837%` : `25%` };
  position: relative;

  a {
    display: block;
    ${postType !== 'partner' && `
      border-top-left-radius: 80px;
    `}
    overflow: hidden;
    box-shadow: 8px 8px 7px ${rgba(colors.black, 0.08)};

    ${mobileView} {
      height: auto;
    }
  }
  ${(!hideAssets) ? `
    &:after {
      content: '';
      position: absolute;
      bottom: -108px;
      right: -102px;
      background-image: url(${Plant});
      background-size: contain;
      background-repeat: no-repeat;
      width: 230px;
      height: 235px;
      z-index: 10;
      pointer-events: none;

      ${mobileView} {
        width: 148px;
        height: 150px;
        bottom: -79px;
        right: -54px;
      }
    }` : ``
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display:block;
  }

  ${mobileView} {
    margin-bottom: 22px;
    min-height: 0;
    width: 100%;
    border-top-left-radius: 58pxs;
  }
`,
);

export const Published = styled.div(
  ({ theme: { colors, spacing, fontSizes } }) => `
    color: ${colors.black};
    margin-bottom: 22px;
    font-size: ${fontSizes.xxs};
    line-height: 1.23;
    letter-spacing: -0.04px;
`,
);

export const Content = styled.div(
  ({ theme: { mobileView }, postType, alignment = 'right' }: ThemeType) => `
    width: ${postType !== 'partner' ? `47.163%` : `75%` };
    ${(alignment == 'right') ? `padding-right: 32px;` : `padding-left: 32px;`}
    ${mobileView} {
      width: 100%;
    }
`,
);

export const CustomLink = styled.a``;