import React from 'react';
import FallbackAsset from 'assets/images/fallback.jpg';
import { ImageType } from 'types';
import getSafe from 'utils/getSafe';
import { FallbackImage, GatsbyImage, HtmlImage } from './styles';

interface Props extends ImageType {
  className?: string;
  imageFile?: any;
  sourceUrl?: any;
  altText?: string;
}

const ImageWrapper: React.FunctionComponent<Props> = ({ sourceUrl, imageFile, altText, className }) => {
  if (imageFile && getSafe(() => imageFile.childImageSharp)) {
    return <GatsbyImage className={className} {...imageFile.childImageSharp} alt={altText} />;
  } else if (sourceUrl) {
    return <HtmlImage className={className} src={sourceUrl} alt={altText} />;
  }
  return <FallbackImage className={className} src={FallbackAsset} />;
};

export default ImageWrapper;
