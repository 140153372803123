import React from 'react';
import StyledContent from './styles';
import Block from 'components/Block';
import Title from 'components/Title';
import TitleWrapper from 'components/TitleWrapper';
import SocialLinks from 'components/SocialLinks';
import Form from './Form';
import { set } from 'utils/localStorage';
import isBrowser from 'utils/isBrowser';


interface Props {
  title: string;
  successMessage?: string;
}

interface State {
  formSubmitted: boolean;
  loading: boolean;
}

class SubscribeBlock extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      loading: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://tradablebits.com/tbits-sdk.js";
    script.async = true;
    document.body.appendChild(script);
  }

  onSubmit(event) {
    event.preventDefault();
    const { TBITS } = window;
    if (TBITS) {
      const apiKey = "f0f99164-e15d-495c-8b80-73e567010ca3";

      const data = new FormData(event.target);
      const email = data.get('email');

      TBITS.init(apiKey);

      this.setState({
        loading: true
      })

      TBITS.optInFan(data)
        .then(response => {
          this.setState({
            formSubmitted: true,
            loading: false
          });
          if (isBrowser()) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'subscribe',
              'formLocation': 'block'
            });
          }
          if (email) {
            set('email', email);
            set('subscribed', true);
          }
          setTimeout(() => {
            this.setState({ formSubmitted: false });
          }, 5000);
        })
        .catch(error =>{
          this.setState({
            loading: false
          });
          console.log('Request failed', error)
        });
    }
  }

  render() {
    const { title, successMessage } = this.props;
    const { formSubmitted, loading } = this.state;
    return (
      <StyledContent>
        <Block>
          <TitleWrapper>
            <Title size="medium">{title}</Title>
          </TitleWrapper>
          {formSubmitted &&
            <div dangerouslySetInnerHTML={{ __html: successMessage ? successMessage : "Thank you for subscribing" }} />
          }
          {!formSubmitted &&
            <Form onSubmit={this.onSubmit} loading={loading} />
          }
          <SocialLinks />
        </Block>
      </StyledContent>
    )
  }
};

export default SubscribeBlock;
