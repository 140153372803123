import React from 'react';
import StyledCta from './styles';
import { LinkType } from 'types';
import { StyledButtonType } from 'types';

const Cta: React.StatelessComponent<StyledButtonType & LinkType> = ({
  title,
  target = '',
  url,
  color = 'pink',
  inverted = false,
  size = 'medium',
  children,
  ...other
}) => {
  if (!title || !url) {
    return <></>;
  }

  return (
    <StyledCta href={url} target={target} className="h7" color={color} inverted={inverted} size={size} {...other}>
      <span className="h7">
        {children}
        <span dangerouslySetInnerHTML={{__html : title}} />
      </span>
    </StyledCta>
  );
};

export default Cta;
