import React from 'react';
import { withTheme } from 'styled-components';
import Block from 'components/Block';
import Title from 'components/Title';
import Cta from 'components/Cta';
import Copy from 'components/Copy';
import HtmlContent from 'components/HtmlContent';
import TitleWrapper from 'components/TitleWrapper';
import { ColumnBlockType, PostType, ThemeType } from 'types';
import isMobile from 'utils/isMobile';
import StyledPostListBlock, { PostList } from './styles';
import Card from 'components/Card';

interface Props extends ColumnBlockType, ThemeType {
  items: PostType[];
  description?: string;
}

export const PostLinks: React.FunctionComponent<Props> = ({ title, ctalink, items, description }) => (
  <StyledPostListBlock>
    <Block>
      <TitleWrapper>
        <Title>{title}</Title>
        {!isMobile() && <Cta {...ctalink} />}
      </TitleWrapper>
      <Copy>
        <HtmlContent html={description} />
      </Copy>
      {items && (
        <PostList>
          {items.map((item, key) => (
            <Card key={key} size="small" {...item} />
          ))}
        </PostList>
      )}
      {isMobile() && <Cta {...ctalink} />}
    </Block>
  </StyledPostListBlock>
);

export default withTheme(PostLinks);
