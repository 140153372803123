import Image from 'gatsby-image';
import styled from 'styled-components';

export const HtmlImage = styled.img`
  width: 100%;
  height: auto;
`;

export const GatsbyImage = styled(Image)`
  width: 100%;
  height: auto;
`;

export const FallbackImage = styled.img`
  position: relative;
  width: 100%;
  height: auto;
`;
