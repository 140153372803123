import React from 'react';
import { MenuType, PageLinkType } from 'types';
import SlideMenu from '../SlideMenu';
import PageWrapper, { MenuItem, MenuWrapper, Link } from './styles';
import SocialLinks from 'components/SocialLinks';
import Item from './item';

interface Props extends MenuType {
  items: PageLinkType[];
  openSubscribeMenu: () => void;
}

const MainMenu: React.FunctionComponent<Props> = (props) => {
  return (
    <SlideMenu right={true} {...props}>
      <PageWrapper>
        <MenuWrapper>
          {props.items &&
            props.items.map((item, index) => {
              if(item.parentId) {
                return null;
              }
              return (
              <Item {...item} key={index} />
            )})}
        </MenuWrapper>
        <SocialLinks />
      </PageWrapper>
    </SlideMenu>
  );
};

export default MainMenu;
