import React from 'react';
import { LayoutSidebarType } from 'types';
import { StyledSidebar, Sticky } from './style';

const Sidebar: React.StatelessComponent<LayoutSidebarType> = ({ children }) => (
  <StyledSidebar>
    <Sticky>{children}</Sticky>
  </StyledSidebar>
);

export default Sidebar;
