import { format } from 'date-fns';
import React from 'react';
import { ArtistType } from 'types';
import Image from 'components/Image';
import ArtistSocialLinks from 'components/ArtistSocialLinks';
import SpotifyEmbed from 'components/SpotifyEmbed';
import Video from 'components/Video';
import ArtistWrapper, { ArtistDetail, Bio, ContentWrapper, LeadingContent, SocialsWrapper, Title } from './styles';

const Artist: React.StatelessComponent<ArtistType & { showSubscribeModal: () => void }> = ({
  showSubscribeModal,
  artistId: id,
  title,
  featuredImage,
  socials,
  uri,
  artistDetails: {
    appleMusic,
    moodagent,
    artistBio,
    australiaOnly,
    country,
    facebook,
    instagram,
    soundcloud,
    spotify,
    spotifyPlayer,
    twitter,
    video,
    website,
    youtube,
    tiktok,
    sessionArtistRelationship,
  },
}) => {
  const feature = featuredImage?.node ? featuredImage.node : null;
  return (
  <ArtistWrapper id={id}>
    <LeadingContent>{video && video.url ? <Video {...video} poster={feature} /> : <Image {...feature} />}</LeadingContent>
    {spotifyPlayer && spotifyPlayer && <SpotifyEmbed {...spotifyPlayer} />}
    <ContentWrapper>
        <SocialsWrapper>
          <ArtistSocialLinks
            links={{
              moodAgent: moodagent,
              facebook: facebook,
              instagram: instagram,
              spotify: spotify,
              twitter: twitter,
              appleMusic: appleMusic,
              soundCloud: soundcloud,
              youtube: youtube,
              tikTok: tiktok,
              website: website
            }}
          />
        </SocialsWrapper>
      <div>
        <Title className="h3" dangerouslySetInnerHTML={{ __html: title }} />
        <div style={{ marginBottom: 10 }}>
          {sessionArtistRelationship &&
            sessionArtistRelationship.map(({ sessionDetails: { sessionDate, startTime, endTime }, stages }, index) => (
              <ArtistDetail key={index} className="p4">
                {sessionDate && format(sessionDate, 'dddd')}
                {stages && (stages.nodes.length > 0) && ` • ${stages.nodes[0].name}`}
                {startTime && endTime && ` • ${format(startTime, 'h:mma')} AEST`}
              </ArtistDetail>
            ))}
        </div>

        <Bio className="p4" dangerouslySetInnerHTML={{ __html: artistBio }} />
      </div>
    </ContentWrapper>
  </ArtistWrapper>
)};

export default Artist;
