import React from 'react';
import { format, distanceInWordsToNow } from 'date-fns';
import { SessionType } from 'types';
import Title from 'components/Title';
import Image from 'components/Image';
import { ImageContainer, ContentContainer, StyledA, Note, Excerpt } from './styles';

interface Props {
  featuredImage?: string;
  title?: string;
  sponsored?: any;
  link?: any;
  data?: any;
  size?: string;
  shortExcerpt ?: any;
  artistDetails ?: any;
  date?: any;
}

export const Card: React.StatelessComponent<Props> = ({ featuredImage, title, sponsored, link: slug, outLink, data, size, shortExcerpt, artistDetails, date }) => {
  let foundSession: SessionType;
  if (artistDetails && artistDetails.sessionArtistRelationship) {
    foundSession = artistDetails.sessionArtistRelationship.find(({ sessionDetails }) => sessionDetails.sessionDate);

    if (foundSession) {
      foundSession = {
        ...foundSession,
        sessionDate: format(foundSession.sessionDetails.sessionDate, 'dddd'),
      };
    }
  }
  const feature = featuredImage?.node ? featuredImage.node : null;
  return (
      <StyledA href={outLink && outLink.url ? outLink.url : slug} target={outLink && outLink.url && '_blank'}>
        {feature && <ImageContainer>
          <Image {...feature} />
        </ImageContainer>
      }
        <ContentContainer>
          {date &&
            <Note>{distanceInWordsToNow(date, { addSuffix: true })}</Note>
          }
          <Title size={size === 'small' ? 'small' : 'medium'}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Title>
          {foundSession && foundSession.sessionDate && (
            <Note>{foundSession.stage ? `${foundSession.stage} • ${foundSession.sessionDate}` : foundSession.sessionDate}</Note>
          )}
          {sponsored && sponsored.sponsored && <Note>Sponsored content</Note>}
          {shortExcerpt && shortExcerpt.shortExcerpt && <Excerpt className="p4" dangerouslySetInnerHTML={{ __html: shortExcerpt.shortExcerpt }} /> }
        </ContentContainer>
      </StyledA>
  )
}

export default Card;
