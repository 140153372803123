import {
  AccordionBlock,
  AppBlock,
  ExploreLineupBlock,
  FeaturedPostBlock,
  HtmlTextBlock,
  ImageBlock,
  FeaturedNewsBlock,
  NewsSnippetsBlock,
  LineupBlock,
  PostListBlock,
  SocialBlock,
  SpotifyBlock,
  TicketBlock,
  VideoBlock,
  VideosBlock,
  CodeEmbed,
  LineupListBlock,
  GalleryBlock,
  LatestNewsBlock,
  ContentBlock,
  PersonalisedPlaylist,
  SubscribeBlock,
  SubscribeForm,
  AdBlock,
  TextImage,
} from '../blocks';

export default {
  accordionBlock: AccordionBlock,
  app: AppBlock,
  exploreLineup: ExploreLineupBlock,
  faqBlock: AccordionBlock,
  featured: FeaturedPostBlock,
  featuredNews: FeaturedNewsBlock,
  newsSnippets: NewsSnippetsBlock,
  htmlTextBlock: HtmlTextBlock,
  image: ImageBlock,
  latestNews: LatestNewsBlock,
  lineup: LineupBlock,
  postBlocks: PostListBlock,
  social: SocialBlock,
  spotify: SpotifyBlock,
  tickets: TicketBlock,
  video: VideoBlock,
  videos: VideosBlock,
  codeEmbed: CodeEmbed,
  lineupList: LineupListBlock,
  galleryBlock: GalleryBlock,
  contentBlock: ContentBlock,
  personalisedPlaylist: PersonalisedPlaylist,
  subscribeBlock: SubscribeBlock,
  subscriptionForm: SubscribeForm,
  adBlock: AdBlock,
  imageText: TextImage,
};
