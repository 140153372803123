import React from 'react';
import Block from 'components/Block';
import Title from 'components/Title';
import Cta from 'components/Cta';
import TitleWrapper from 'components/TitleWrapper';
import StyledVideosBlock, { VideosWrapper } from './styles';
import ReactPlayer from 'react-player';
import { LinkType } from 'types';
import isMobile from 'utils/isMobile';
import getSafe from 'utils/getSafe';

interface Video {
  title: string;
  video: {
    url: string;
  };
}
interface Props {
  ctalink?: LinkType;
  title: string;
  videos: Video[];
}
interface State {
  url: string;
  videoTitle: string;
  activeIndex: number;
  playing: boolean;
}

class Videos extends React.Component<Props, State> {
  player: any;

  constructor(props) {
    super(props);

    const { videos } = props;

    this.state = {
      activeIndex: 0,
      url: getSafe(() => videos[0].video),
      videoTitle: getSafe(() => videos[0].title),
      playing: true,
    };
  }

  load = (video, index) =>
    this.setState({
      url: video.video,
      videoTitle: video.title,
      activeIndex: index,
      playing: true,
    });

  render() {
    const { title, ctalink, videos } = this.props;
    const { url, videoTitle } = this.state;

    return (
      <StyledVideosBlock>
      <Block>
        <TitleWrapper>
          <Title>{title}</Title>
          {!isMobile() && title && <Cta {...ctalink} />}
        </TitleWrapper>
        {videos && (
          <VideosWrapper>
            <div className="main-video">
              <div className="video-player">
                <ReactPlayer url={url} light={false} className="react-player" controls={false} playing={false} height="100%" width="100%" />
              </div>
              <h3 className="h4">{videoTitle}</h3>
            </div>
            <div className="video-playlist">
              {videos.map((video, index) => (
                <div
                  key={`video-${index}`}
                  onClick={() => this.load(video, index)}
                  className={this.state.activeIndex === index ? 'active' : 'video-link'}
                >
                  <div className="video-poster">
                    <ReactPlayer
                      url={getSafe(() => video.video)}
                      light={false}
                      className="react-player"
                      controls={false}
                      playing={false}
                      height="100%"
                      width="100%"
                      config={{
                        youtube: {
                          playerVars: { modestbranding: 1 },
                        },
                        facebookConfig: { appId: '563405637511416' },
                      }}
                    />
                  </div>
                  <h4 className="h7">{video.title}</h4>
                </div>
              ))}
            </div>
          </VideosWrapper>
        )}
      </Block>
      </StyledVideosBlock>
    );
  }
}

export default Videos;
