import styled from 'styled-components';
import { ThemeType } from 'types';

export const ContentInner = styled.div(
  ({ theme: { mobileView } }: ThemeType) => `
    position: relative;
    display: block;
    width: 100%;

    ${mobileView} {
      min-height: 0;
    }
`,
);

export default ContentInner;

export const LayoutInner = styled.div(
  ({ theme: { mobileView } }: ThemeType) => `
    position: relative;
    display: block;
    max-width: 1216px;
    width: 100%;
    flex: auto;
    > *:first-child {
      padding-top: 0;
    }
    ${mobileView} {
      min-height: 0;
    }
`,
);
