import React from 'react';
 import { ImageType } from 'types';
 import Image from 'components/Image';
 import Carousel from 'components/Carousel';
 import { GalleryImage } from './styles';


 interface Props {
   images: [ImageType];
 }

 export const GalleryFallback: React.StatelessComponent<Props> = ({ images }) => (
   <div>
   {images && (
       <Carousel size='large'>
         {images.map(({image}, index) => {
           return (
             <div key={index}>
               <GalleryImage key={`slide-${index}`}><Image {...image} /></GalleryImage>
             </div>
           );
         })}
       </Carousel>
   )}
   </div>
 );

 export default GalleryFallback;