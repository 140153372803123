import styled from 'styled-components';

export default styled.div(
  ({ theme: { colors } }) => `
    display: flex;

    .video-player, 
    .video-poster {
      position: relative;
      height: 0;
      padding-bottom: 56.25%;
      width: 100%;
      background-color: transparent;
      
      & > *:first-child {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .poster {
      width: 100%;
      height: auto;
    }
    div.fb-video {
      background-color: transparent !important;

      > span {
        background-color: ${colors.black};
      }
    }
`,
);
