import styled from 'styled-components';

export const SpotifyEmbedWrapper = styled.div`
  width: 100%;
`;

export const IframeWrapper = styled.div`
  position: relative;
  width: 100%;

  iframe {
    border: none;
    width: 100%;
    height: 80px;
  }
`;
