import React from 'react';
import Ticket from 'components/Ticket';
import { TicketBlockType } from 'types';
import { TicketsWrapper } from './styles';

export const TicketBlock: React.FunctionComponent<TicketBlockType> = ({ tickets }) => (
    <TicketsWrapper>{tickets && tickets.map((ticket, index) => <Ticket key={`ticket-${index}`} {...ticket} />)}</TicketsWrapper>
);

export default TicketBlock;
