import React from 'react';
import { ArtistType, ColumnBlockType } from 'types';
import Carousel, { CarouselSize } from 'components/Carousel';
import Block from 'components/Block';
import Title from 'components/Title';
import TitleWrapper from 'components/TitleWrapper';
import Cta from 'components/Cta';
import StyledExploreLineupBlock from './styles';
import isMobile from 'utils/isMobile';

interface Props extends ColumnBlockType {
  artists: ArtistType[];
  carouselSize?: CarouselSize;
}

export const ExploreLineup: React.StatelessComponent<Props> = ({ type = 'large', title, ctalink, artists }) => (
  <StyledExploreLineupBlock>
    <Block>
      <TitleWrapper>
        <Title>{title}</Title>
        {!isMobile() && <Cta {...ctalink} color="blue" />}
      </TitleWrapper>
      {artists && <Carousel size="large" items={artists} carousel={true} />}
      {isMobile() && <Cta {...ctalink} color="blue" />}
    </Block>
  </StyledExploreLineupBlock>
);

export default ExploreLineup;
