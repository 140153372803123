import styled from 'styled-components';
import Copy from 'components/Copy/styles';

export default styled.section(
  ({alignment = 'left',
    theme: {
      colors,
      mobileView,
      spacing,
      utils: { rgba },
    },
  }) => `
    text-align: ${alignment};
    img {
      max-width: 100%;
      height: auto;
    }
    ${Copy} {
      margin-top: 26px;
      margin-bottom: 34px;
      max-width: 864px;
      ${alignment=='center' ? 'margin-left: auto; margin-right: auto;' : ''}
      ${alignment=='right' ? 'margin-left: auto;' : ''}
    }


      ${mobileView} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
`,
);
