import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const ArrowUp: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 13 9" {...props}>
    <path fill-rule="evenodd" d="M6.5 0L0 9h13z" clip-rule="evenodd"/>
  </StyledSvg>
);

export default ArrowUp;
