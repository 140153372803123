import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { CarouselSize } from 'components/Carousel';
import { PostType, ColumnBlockType } from 'types';
import FeaturedNewsBlock from 'blocks/FeaturedNews';

interface Props extends ColumnBlockType {
  items?: PostType[];
  news?: PostType[];
  carouselSize?: CarouselSize;
  carousel: boolean;
}

export const LatestNews: React.StatelessComponent<Props> = ({ type = 'medium', carouselSize = 'small', title, ctalink }) => (
  <StaticQuery
    query={graphql`
      query News {
          allWpPost(limit: 6, sort: {order: ASC, fields: date}) {
            nodes {
              title
              featuredImage {
                node {
                  ...Image
                }
              }
              sponsored {
                fieldGroupName
                sponsorName
                sponsored
              }
              excerpt
              date
              link
            }
          }
      }
    `}
    render={(data) => (
      <FeaturedNewsBlock carouselSize="small" title={title} ctalink={ctalink} posts={data.posts.nodes} carousel={true} />
    )}
  />
);

export default LatestNews;
