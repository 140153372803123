import styled from 'styled-components';
import Block from '../Block';
import StyledCta from 'components/Cta/styles';
import { ThemeType } from 'types';

export default styled(Block)(
  ({ theme: { spacing } }: ThemeType) => `
    text-align: center;
    ${StyledCta} {
      margin-top: ${spacing.x3};
    }
`,
);
