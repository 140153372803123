import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const CarouselArrow: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 40 40" {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
        <stop stopColor="#59FF57" offset="0%" />
        <stop stopColor="#00FECE" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M57.052 2756.084l37.812 18.905a.094.094 0 0 0 .136-.085v-37.808a.094.094 0 0 0-.136-.085l-37.812 18.905a.094.094 0 0 0 0 .168z"
      transform="translate(-56 -2736)"
      fill="#000"
      stroke="url(#a)"
      strokeWidth="2"
      fillRule="evenodd"
      fillOpacity=".5"
    />
  </StyledSvg>
);

export default CarouselArrow;
