import styled, { css, keyframes } from 'styled-components';
import { ThemeType } from 'types';
import { Link } from 'react-scroll';

interface Props extends ThemeType {
  align: string;
}

const hoverAnimation = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.5;
  }
`;

export const LineupWrapper = styled.div(
  ({ theme: { colors, mobileView, extraLargeView, spacing, width, fontFamilies }, align }: Props) => `
  margin: 0 auto;
  text-align: ${align};
  margin: 0 auto;
  max-width: ${width.default};

  color: ${colors.white};
  font-family: ${fontFamilies.lineup};
  font-size: 3.2vw;
  letter-spacing: -0.04em;
  line-height: 1.28;

  & > *:not(:last-child) {
    margin-bottom: ${spacing.x6};
  }

  ${mobileView} {
    font-size: 4.5vw;
  }

  ${extraLargeView} {
    font-size: 28px;
  }
`,
);

export const ArtistWrapper = styled.div(
  ({ theme: { colors, lineupSeparator, mobileView } }) => `
    display: inline;
    &::after {
      content: " ";
      letter-spacing: 0.4em;
      background-image: ${lineupSeparator};
      background-size: contain;
      background-position: 50% 25%;
      background-repeat: no-repeat;
      font-size: 0.44em;
      vertical-align: middle;

      ${mobileView} {
        letter-spacing: 0.3em;
      }
    }

    &.lineup-link-active {
      ${ScrollingArtistLink} {
        color: ${colors.blue};
        opacity: 1 !important;
        animation: none !important;
      }
    }
`,
);

export const ArtistLink = styled.a(
  () => css`
    white-space: nowrap;
    text-transform: uppercase;
    margin: 0 0.2em;
    cursor: pointer;

    transition: transform 0.1s ease-in-out;

    &:hover {
      text-decoration: none;
      animation: ${hoverAnimation} 0.8s ease-in-out infinite;
    }
  `,
);

export const ScrollingArtistLink = styled(Link)(
  ({ theme: { colors, mobileView } }: ThemeType) => css`
    white-space: nowrap;
    text-transform: uppercase;
    margin: 0 0.2em;
    cursor: pointer;
    color: ${colors.white};

    transition: transform 0.1s ease-in-out;

    &:hover {
      text-decoration: none;
      animation: ${hoverAnimation} 0.8s ease-in-out infinite;
    }
  `,
);

export const ScrollHere = styled.div(
  ({ theme: { mobileView } }) => `
  display: block;
  position: relative;
  height: 80vh;
  width: 100%;

  ${mobileView} {
    display: none;
  }

  svg {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 60%;
    margin: 0 auto;
  }
`,
);

export default LineupWrapper;
