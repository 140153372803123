import React from 'react';
import SpotifyPlayer from 'react-spotify-player';
import { IframeWrapper, SpotifyEmbedWrapper } from './styles';

interface Props {
  embed?: string;
}

const SpotifyEmbed: React.FunctionComponent<Props> = (embed) => (
  <SpotifyEmbedWrapper>
    {embed && (
      <IframeWrapper>
        <SpotifyPlayer
          uri={embed}
          />
      </IframeWrapper>
    )}
  </SpotifyEmbedWrapper>
);

export default SpotifyEmbed;
