import React from 'react';
import { ImageType, LinkType } from 'types';
import Image from 'components/Image';
import Block from 'components/Block';
import Title from 'components/Title';
import Copy from 'components/Copy';
import Cta from 'components/Cta';
import HtmlContent from 'components/HtmlContent';
import StyledPersonalisedPlaylistBlock from './styles';
import { ContentWrapper, ImageWrapper } from 'blocks/FillBlock/styles';
import isMobile from 'utils/isMobile';
import isImageValid from 'utils/isImageValid';

interface Props {
  title: string;
  ctalink: LinkType;
  subtitle: string;
  description: string;
  image: ImageType;
  mobileImage?: ImageType;
}

export const PersonalisedPlaylistBlock: React.StatelessComponent<Props> = ({ title, ctalink, description, image, mobileImage }) => {
  const imageProps = isMobile() && isImageValid(mobileImage) ? mobileImage : image;

  return (
    <StyledPersonalisedPlaylistBlock>
      <Block>
        <ImageWrapper>
          <Image {...imageProps} />
        </ImageWrapper>
        <ContentWrapper>
          <Title size="medium">{title}</Title>
          {description && (
            <Copy>
              <HtmlContent html={description} />
            </Copy>
          )}
          <Cta {...ctalink} />
        </ContentWrapper>
      </Block>
    </StyledPersonalisedPlaylistBlock>
  );
};

export default PersonalisedPlaylistBlock;
