import React from 'react';
import { LinkType } from 'types';
import Block from 'components/Block';
import Title from 'components/Title';
import Cta from 'components/Cta';
import Copy from 'components/Copy';
import HtmlContent from 'components/HtmlContent';
import TitleWrapper from 'components/TitleWrapper';
import StyledWysiwygBlock from './styles';

export interface Props {
  title: string;
  ctalink: LinkType;
  htmltextblock: string;
  alignment?: string;
}

export const Image: React.StatelessComponent<Props> = ({ title, ctalink, htmltextblock, alignment }) => (
  <StyledWysiwygBlock alignment={alignment}>
    <Block>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
      <Copy>
        <HtmlContent html={htmltextblock} />
      </Copy>
      <Cta {...ctalink} />
    </Block>
  </StyledWysiwygBlock>
);

export default Image;
