import React from 'react';
import { ColumnBlockType, ImageType } from 'types';
import Block from 'components/Block';
import Title from 'components/Title';
import Cta from 'components/Cta';
import TitleWrapper from 'components/TitleWrapper';
import isMobile from 'utils/isMobile';
import { GalleryWrapper } from './styles';
import {GalleryFallback} from './galleryFallback'

const ClientSideOnlyLazy = React.lazy(() =>
  import("./gallery")
)

export interface Props extends ColumnBlockType {
  images: [ImageType];
}

export const Gallery: React.StatelessComponent<Props> = ({ title, ctalink, images }) => {
  const isSSR = typeof window === "undefined"
  return (
      <Block>
      <GalleryWrapper>
        <TitleWrapper>
          {title && <Title>{title}</Title> }
          {!isMobile() && <Cta {...ctalink} />}
        </TitleWrapper>
        <GalleryFallback images={images} />
        {isMobile() && <Cta {...ctalink} />}
        </GalleryWrapper>
      </Block>
  );
};

export default Gallery;
