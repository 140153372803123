import isBrowser from './isBrowser';

const items = {
  userId: 'USER_ID',
  userName: 'USER_NAME',
  lastDismissedAnnouncement: 'LAST_DISMISSED_ANNOUNCEMENT',
};

export const get = (item: string) => {
  if (!isBrowser()) return null;
  const data = window.localStorage.getItem(item);

  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};
export const set = (item: string, value: string) => isBrowser() && window.localStorage.setItem(item, value);
export const remove = (item: string) => isBrowser() && window.localStorage.removeItem(item);

export const getUserName = () => get(items.userName);
export const setUserName = (userName: string) => set(items.userName, userName);
export const removeUserName = () => remove(items.userName);

export const getUserId = () => get(items.userId);
export const setUserId = (userId: string) => set(items.userId, userId);
export const removeUserId = () => remove(items.userId);

export const getLastDismissedAnnouncement = () => get(items.lastDismissedAnnouncement);
export const setLastDismissedAnnouncement = (announcement: string) => set(items.lastDismissedAnnouncement, announcement);
export const removeLastDismissedAnnouncement = () => remove(items.lastDismissedAnnouncement);
