import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const SoundCloudIcon: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 96 54" {...props}>
    <path d="M80.86,23.42a14.41,14.41,0,0,0-5.81,1.18,26.92,26.92,0,0,0-4.22-12.44,26.45,26.45,0,0,0-9.54-8.9A25.94,25.94,0,0,0,48.56,0a25.26,25.26,0,0,0-5.94.72C41.69,1,41.23,2,41.23,3.53V52.82a1.45,1.45,0,0,0,.2.64A1.07,1.07,0,0,0,42,54L80.9,54a14.49,14.49,0,0,0,10.66-4.48A14.83,14.83,0,0,0,96,38.69a14.77,14.77,0,0,0-4.44-10.78A14.54,14.54,0,0,0,80.86,23.42Z" />
    <path d="M3.26,27.82a3.11,3.11,0,0,0-2.3,1,3.26,3.26,0,0,0-1,2.38V44.31a3.15,3.15,0,0,0,1.3,2.74,3.53,3.53,0,0,0,2.77.65,3.11,3.11,0,0,0,1.94-1,4,4,0,0,0,.56-2.4V31.17a3.26,3.26,0,0,0-1-2.38A3.18,3.18,0,0,0,3.26,27.82Z" />
    <path d="M13.54,16a3.11,3.11,0,0,0-2.3,1,3.15,3.15,0,0,0-1,2.33V49.92a2.25,2.25,0,0,0,.92,2,3.91,3.91,0,0,0,2.35.66,4,4,0,0,0,2.39-.66,2.25,2.25,0,0,0,.91-2V19.3A3.31,3.31,0,0,0,13.54,16Z" />
    <path d="M23.85,20a3.2,3.2,0,0,0-2.36,1,3.27,3.27,0,0,0-1,2.4V51.37h0a2.26,2.26,0,0,0,.94,2,4.11,4.11,0,0,0,2.41.66,3.91,3.91,0,0,0,2.35-.66,2.27,2.27,0,0,0,.92-2V23.42a3.32,3.32,0,0,0-1-2.4A3.11,3.11,0,0,0,23.85,20Z" />
    <path d="M35.11,1.9a3.18,3.18,0,0,0-2.9.86,4.32,4.32,0,0,0-1.43,3.45v45.3h0q0,2.49,3.3,2.49c2.21,0,3.31-.83,3.31-2.49V6.21Q37.39,2.4,35.11,1.9Z" />
  </StyledSvg>
);

export default SoundCloudIcon;
