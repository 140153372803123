import React from 'react';
import Block from 'components/Block';
import Title from 'components/Title';
import SubscriptionSimpleForm from 'components/SubscriptionSimpleForm';
import TitleWrapper from 'components/TitleWrapper';

export interface Props {
  title: string;
}

export const SubscribeForm: React.FunctionComponent<Props> = ({ title }) => (
    <Block>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
      <SubscriptionSimpleForm />
    </Block>
);

export default SubscribeForm;
