import React from 'react';
import { StyledBlock, StyledBlockInner } from './styles';

interface Props {
  children: React.ReactNode;
}

const Block: React.StatelessComponent<Props> = ({ children }) => (
  <StyledBlock>
    <StyledBlockInner>{children}</StyledBlockInner>
  </StyledBlock>
);

export default Block;
