import { distanceInWordsToNow } from 'date-fns';
import React from 'react';
import Image from 'components/Image';
import Video from 'components/Video';
import Title from 'components/Title';
import { HorizontalAlignmentType } from 'types';
import StyledFeaturedPostBlock, { Media, Published, Content } from './styles';
import Block from 'components/Block';
import Cta from 'components/Cta';

interface Props {
  alignment?: HorizontalAlignmentType;
  post: any;
}

export const FeaturedPost: React.FunctionComponent<Props> = ({
  alignment = 'right',
  post: { date, title, shortExcerpt, featuredImage, featuredVideo, link, sponsored, outLink, content, contentType, hideAssets = false },
}) => {
  const feature = featuredImage?.node ? featuredImage.node : null;
  const excerpt = shortExcerpt?.shortExcerpt ? shortExcerpt : content ? content : null;
  const postType = contentType?.node?.name ? contentType.node.name : null;
  const hide = hideAssets || postType === 'partner';
  return (
    <StyledFeaturedPostBlock alignment={alignment} postType={postType} hideAssets={hide}>
      <Block>
        <Media postType={postType} hideAssets={hide}>
          {featuredVideo && featuredVideo.url ? (
            <Video {...featuredVideo} poster={feature} />
          ) : (
            <a href={outLink && outLink.url ? outLink.url : link} target={outLink && outLink.url && '_blank'}>
              <Image {...feature} />
            </a>
          )}
        </Media>
        <Content postType={postType} hideAssets={hide}>
          <h1>
            {date && <Published>{distanceInWordsToNow(date, { addSuffix: true })}</Published>}
            <a href={outLink && outLink.url ? outLink.url : link} target={outLink && outLink.url && '_blank'}>
              <Title size="medium">
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Title>
            </a>
            {sponsored && sponsored.sponsored && <span>Sponsored content</span>}
          </h1>
          <a href={outLink && outLink.url ? outLink.url : link} target={outLink && outLink.url && '_blank'}>
            {excerpt && <div className="p4 content" dangerouslySetInnerHTML={{ __html: excerpt }} /> }
          </a>

          <Cta url={outLink && outLink.url ? outLink.url : link} target={outLink && outLink.url && '_blank'} title="Learn More" />
        </Content>
      </Block>
    </StyledFeaturedPostBlock>
  );
};

export default FeaturedPost;
