import * as Icons from '../icons';

export default {
  appleMusic: Icons.AppleMusicIcon,
  applemusic: Icons.AppleMusicIcon,
  burger: Icons.BurgerMenuIcon,
  close: Icons.CloseIcon,
  email: Icons.EmailIcon,
  externalLink: Icons.ExternalLinkIcon,
  facebook: Icons.FacebookIcon,
  instagram: Icons.InstagramIcon,
  paperPlane: Icons.PaperPlaneIcon,
  search: Icons.SearchIcon,
  soundCloud: Icons.SoundCloudIcon,
  spotify: Icons.SpotifyIcon,
  toTop: Icons.ToTopIcon,
  twitter: Icons.TwitterIcon,
  website: Icons.ExternalLinkIcon,
  youtube: Icons.YoutubeIcon,
  carouselArrow: Icons.CarouselArrowIcon,
  add: Icons.AddIcon,
  remove: Icons.RemoveIcon,
  moodAgent: Icons.MoodAgentIcon,
  tikTok: Icons.TikTokIcon,
  moodagent: Icons.MoodAgentIcon,
  tiktok: Icons.TikTokIcon,
};
