import React from 'react';
import Image from 'components/Image';
import GooglePlayImage from 'assets/images/google-play.png';
import AppleStoreImage from 'assets/images/apple-store.png';
import { AppButtons } from './styles';

export const Buttons: React.StatelessComponent<{
  appleLink: string;
  androidLink: string;
  withStroke: boolean;
}> = ({ appleLink, androidLink, withStroke }) => {
  return (
    <AppButtons backgroundStroke={withStroke}>
      {appleLink && (
        <a href={appleLink} target="_blank">
          <Image className="AppleLink" sourceUrl={AppleStoreImage} alt="Get it on Google Play" />
        </a>
      )}
      {androidLink && (
        <a href={androidLink} target="_blank">
          <Image className="AndroidLink" sourceUrl={GooglePlayImage} alt="Download on the App Store" />
        </a>
      )}
    </AppButtons>
  );
};

export default Buttons;
