import React from 'react';
import Image from 'components/Image';
import AppMockup from 'assets/images/phone_sitg-2020.png';
import AppButtons from 'components/AppButtons';
import Block from 'components/Block';
import Title from 'components/Title';
import Copy from 'components/Copy';
import { ContentWrapper } from 'blocks/FillBlock/styles';
import StyledAppBlock, { ImageWrapper } from './styles';
import { BackgroundNoise } from 'layout/components/styles';

interface Props {
  title: string;
  subtitle: string;
  applelink?: string;
  androidlink?: string;
}

export const AppBlock: React.StatelessComponent<Props> = ({ title, subtitle, androidlink, applelink }) => (
  <StyledAppBlock>
    <Block>
      <ImageWrapper>
        <BackgroundNoise />
        <Image sourceUrl={AppMockup} alt="MySplendour App Mockup" />
      </ImageWrapper>
      <ContentWrapper>
        <Title size="medium">{title}</Title>
        {subtitle && <Copy>{subtitle}</Copy>}
        <AppButtons withStroke={true} appleLink={applelink} androidLink={androidlink} />
      </ContentWrapper>
    </Block>
  </StyledAppBlock>
);

export default AppBlock;
