import { distanceInWordsToNow } from 'date-fns';
import React from 'react';
import Image from 'components/Image';
import Title from 'components/Title';
import { HorizontalAlignmentType } from 'types';
import StyledFeaturedPostBlock, { Media, CustomLink, Content } from '../FeaturedPost/styles';
import Block from 'components/Block';
import Cta from 'components/Cta';

interface Props {
  alignment?: HorizontalAlignmentType
  title?: string
  image?: any
  link?: {
    title?: string
    url?: string
    target?: string
  }
  hideassets?: boolean
  content?: string
}

export const TextImage: React.FunctionComponent<Props> = ({
  alignment = 'right', title, image, content, link, hideassets = false
}) => {
  const feature = image || null;
  const excerpt = content || null;
  return (
    <StyledFeaturedPostBlock alignment={alignment} hideAssets={hideassets}>
      <Block>
        <Media hideAssets={hideassets}>
            <CustomLink as={link ? `a` : `span`} href={link && link.url} target={link && link.target}>
              <Image {...feature} />
            </CustomLink>
        </Media>
        <Content hideAssets={hideassets} alignment={alignment}>
          <h1>
            <CustomLink as={link ? `a` : `span`} href={link && link.url} target={link && link.target}>
              <Title size="medium">
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Title>
            </CustomLink>
          </h1>
          <CustomLink as={link ? `a` : `span`} href={link && link.url} target={link && link.target}>
            {excerpt && <div className="p4 content" dangerouslySetInnerHTML={{ __html: excerpt }} /> }
          </CustomLink>

          {link && <Cta url={link.url} target={link.target} title={link.title} />}
        </Content>
      </Block>
    </StyledFeaturedPostBlock>
  );
};

export default TextImage;
