import React from 'react';
import AnnouncementBanner from '../AnnouncementBanner';
import IconButton from 'components/IconButton';
import Logo from '../Logo';
import { PageLinkType } from 'types';
import MM01 from 'assets/images/mega-menu-01.png';
import MM02 from 'assets/images/mega-menu-02.png';
import MM03 from 'assets/images/mega-menu-03.png';
import Image from 'components/Image';
import { HeaderWrapper, HeaderShadow, MenuWrapper, HeaderContent, PageLinksWrapper, MainHeader, IconsWrapper, LinksWrapper, SubLinks, MenuButton, SublinksInner } from './styles';
import AnnouncementType from 'types/Announcement';
import TicketButton from 'components/TicketButton';
import PrimaryHeader from './Primary';
import UniversalLink from 'components/UniversalLink';
import LanguageSelector from 'components/LanguageSelector';

interface Props {
  pageWrapId?: string;
  openSubscribeMenu: () => void;
  openSearch: () => void;
  openMainMenu: () => void;
  pageLinks?: PageLinkType[];
  announcement: AnnouncementType;
  hideAnnouncement: () => void;
  openMySplendourModal: () => void;
  hidePrimaryHeader?: boolean;
}

const Images = [MM01, MM02, MM03].sort(() => Math.random() - 0.5);
let iKey = 0;
const getImage = () => {
  iKey += 1;
  return Images[iKey % Images.length];
}
const Header: React.FunctionComponent<Props> = ({
  pageWrapId,
  openSubscribeMenu,
  openSearch,
  openMainMenu,
  pageLinks,
  announcement,
  hideAnnouncement,
  language,
}) => (
  <HeaderWrapper>
    {announcement && <AnnouncementBanner {...announcement} hide={hideAnnouncement} />}
    <HeaderShadow />
    <PrimaryHeader pageWrapId={pageWrapId}>
      <HeaderContent>
        <Logo />
        <IconButton type="paperPlane" size="small" text="Subscribe" inverted={true} color="black" onClick={openSubscribeMenu} />
      </HeaderContent>
    </PrimaryHeader>
    <TicketButton />
    <MainHeader>
      <HeaderContent>
        <MenuWrapper>
          <Logo />
          <TicketButton />
          {pageLinks && (
            <PageLinksWrapper>
              {pageLinks.map(( menuItem , key) => {
                const { parentId, childItems } = menuItem;
                if(parentId) {
                  return;
                }
                const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url;
                return (
                <LinksWrapper key={key}>
                  <UniversalLink to={path}>
                    {menuItem.label}
                  </UniversalLink>
                  {childItems?.nodes && childItems.nodes.length > 0 && (
                    <SubLinks>
                      <SublinksInner>
                        <div>
                        {childItems.nodes.map((sublink: PageLinkType, sublinkIndex) => {
                          const path = sublink?.connectedNode?.node?.uri ?? sublink.url;
                          return (
                          <span key={sublinkIndex}>
                            <UniversalLink to={path}>
                              {sublink.label}
                            </UniversalLink>
                          </span>
                        )})}
                        </div>
                      </SublinksInner>
                    </SubLinks>
                  )}
                </LinksWrapper>
              )})}
            </PageLinksWrapper>
          )}
        </MenuWrapper>
        <IconsWrapper>
          <IconButton type="paperPlane" size="small" text="Subscribe" inverted={true} color="black" onClick={openSubscribeMenu} hideOnMobile={true} hideOnTablet={true} />
          <LanguageSelector language={language} />
          {/* <IconButton type="search" size="small" inverted={true} onClick={openSearch} color="pink" /> */}
          <MenuButton type="burger" size="small" inverted={true} onClick={openMainMenu} color="transparent" />
        </IconsWrapper>
      </HeaderContent>
    </MainHeader>
  </HeaderWrapper>
);

export default Header;
