import React from 'react';
import Button from 'components/Button';
import SubscribeConsumer from 'context/SubscribeContext';
import { StyledForm } from './styles';

const Form: React.FunctionComponent = ({ onSubmit, loading }) => (
  <SubscribeConsumer>
    {({ openedMenu, openSubscribeMenu }) => (
      <Button inverted={true} onClick={openSubscribeMenu || (() => null)}>
        Subscribe
        {openedMenu}
      </Button>
    )}
  </SubscribeConsumer>
);

export default Form;
