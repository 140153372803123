import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const YoutubeIcon: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 96 68" {...props}>
    <path d="M91.22,5.07C87.57,1.22,83.48,1.2,81.61,1,68.17,0,48,0,48,0h0S27.83,0,14.39,1c-1.87.22-6,.24-9.62,4.09C1.9,8,1,14.67,1,14.67A147.17,147.17,0,0,0,0,30.32v7.33A147.17,147.17,0,0,0,1,53.3S1.9,60,4.77,62.89c3.66,3.86,8.45,3.74,10.59,4.14C23,67.77,48,68,48,68s20.17,0,33.61-1c1.87-.23,6-.24,9.61-4.1C94.1,60,95,53.3,95,53.3a147.17,147.17,0,0,0,1-15.65V30.32a147.17,147.17,0,0,0-1-15.65S94.1,8,91.22,5.07ZM38.09,46.54V19.38L64,33Z" />
  </StyledSvg>
);

export default YoutubeIcon;
