import styled from 'styled-components';
import { ThemeType } from 'types';

export const AppButtons = styled.div(
  ({ theme: { mobileView, spacing, easing } }: ThemeType) => `
      position: relative;

      a {
        display: inline-block;
        transition: transform .3s ${easing};
        
        &:hover {
          transform: scale(1.03);
        }
        
        &:not(:last-of-type) {
          margin-right: ${spacing.x4};
  
          ${mobileView} {
            margin-right: ${spacing.x3};
          }
        }
  
        img {
          display: inline-block;
          width: 135px;
        }
      }
    `,
);
