import React, { SyntheticEvent } from 'react';
import ICONS from 'constants/icons';
import { IconType, IconSizeType } from 'types';
import Button, { StyledSpan } from './styles';
import { colors } from 'components/Button/styles';

interface Props {
  type: IconType;
  onClick: (e: SyntheticEvent) => void;
  size?: IconSizeType;
  text?: string;
  reverse?: boolean;
  textStyle?: string;
  color?: string;
  className?: string;
  inverted?: boolean;
}

const IconButton: React.StatelessComponent<Props> = ({
  type,
  text,
  onClick,
  size,
  textStyle,
  reverse,
  color,
  inverted = false,
  ...props
}) => {
  const Component: React.FunctionComponent<any> = ICONS[type];
  const buttonColor = colors[color] || colors.pink;

  return (
    <Button {...props} color={color} inverted={inverted} onClick={onClick} hasText={!!text} reverse={!!reverse} size={size}>
      <span>
        <Component size={size} fill={buttonColor.text} />
        {text && <StyledSpan>{text}</StyledSpan>}
      </span>
    </Button>
  );
};

export default IconButton;
