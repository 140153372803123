import Content, {PageContainer} from './Content';
import { ContentInner, LayoutInner } from './ContentInner';
import Main from './Main';
import Page from './Page';
import Section from './Section';
import Sidebar from './Sidebar';

const Layout = {
  Content,
  ContentInner,
  Main,
  Page,
  Section,
  Sidebar,
  LayoutInner,
  PageContainer,
};

export default Layout;
