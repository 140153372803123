import styled from 'styled-components';
import { ThemeType } from 'types';
import { StyledBlockInner } from 'components/Block/styles';
import Title from 'components/Title/styles';
import BlueTear from 'assets/images/hero-tear-blue.png';
import SocialLinks from 'components/SocialLinks/styles'
import Button from 'components/Button/styles';

export default styled.div(
  ({
    theme: {
      colors,
      mobileView,
      spacing,
      fontFamilies,
      utils: { rgba },
    },
  }: ThemeType) => `
    background-color: ${colors.blue};
    color: ${colors.black};
    margin-top: -20px;
    margin-bottom: -${spacing.x8};
    padding-top: 68px;
    padding-bottom: 63px;
    position: relative;
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 32px;
    padding-right: 32px;

    ${mobileView} {
      margin-top: 0px;
      margin-bottom: 0;
      padding-top: 43px;
      padding-bottom: 50px;
    }

    ${StyledBlockInner} {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    ${Title} {
      margin-bottom: 31px;
      font-family: ${fontFamilies.default};
      ${mobileView} {
        text-align: center;
        font-size: 26px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    ${SocialLinks} {
      margin-top: ${spacing.x4};
    }
    ${Button} {
      margin: 0;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: ${rgba(colors.eggplant, 0.08)};
      background-image: url(${BlueTear});
      background-repeat: repeat-x;
      background-position: top left;
      background-size: auto 100%;
      width: 100%;
      margin-left: -32px;
      margin-right: -32px;
      height: 11px;
      bottom: -10px;
      z-index: -1;
      box-shadow: 0 4px 7px ${rgba(colors.eggplant, 0.08)};
      
      ${mobileView} {
        height: 14px;
        bottom: -13px;
      }
    }

    a {
      
    }

    p {
      &:first-child {
        margin-top: 0;
      }
    }

    img, 
    iframe {
      max-width: 100%;
    }

    .alignnone {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      height: auto;
    }

    .aligncenter {
      display: block;
      margin: 2em auto;
      height: auto;
    }

    .alignleft,
    .alignright {
      margin-bottom: 0;
      height: auto;
    }
  `,
);

export const StyledForm = styled.form(
  ({
    theme: {
      colors,
      fontSizes,
      fontFamilies,
      utils: { rgba },
    },
    loading
  }: ThemeType) => `
    display: flex;
    width: 100%;
    justify-content: center;
    ${loading ? `opacity: 0.5` : ''}
    input {
      width: 100%;
      max-width: 384px;
      border: 0;
      margin: 0;
      box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};
      padding: 8px 0 9px 17px;
      color: ${colors.black};
      font-family: ${fontFamilies.default};
      font-size: ${fontSizes.xs};
      font-weight: 500;
    }
`,
);
