import React from 'react';
import { MenuType } from 'types';
import SlideMenu from '../SlideMenu';
import SubscriptionForm from '../SubscriptionForm';

const SubscriptionMenu: React.StatelessComponent<MenuType> = (props) => (
  <SlideMenu {...props} right={true} width="480px" mobileWidth="100%">
    <SubscriptionForm formId="_form_18" />
  </SlideMenu>
);

export default SubscriptionMenu;
