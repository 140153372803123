import styled from 'styled-components';
import { ThemeType } from 'types';

const SubTitle = styled.h3(
  ({ theme: { fontFamilies, colors } }: ThemeType) => `
    line-height: 1.1;
    font-family: ${fontFamilies.default};
    color: ${colors.eggplant};
    text-decoration-color: ${colors.eggplant};
  `,
);

export default SubTitle;
