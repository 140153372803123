import React from 'react';
import { StyledPrimaryHeader } from './styles';

interface PrimaryProps {
  pageWrapId?: string;
}

class PrimaryHeader extends React.Component<any, any, PrimaryProps> {
  pageWrap: any;

  state = {
    hasScrolled: false,
  };

  constructor(props) {
    super(props);

    this.handlePageScroll = this.handlePageScroll.bind(this);
  }

  componentDidMount() {
    this.pageWrap = this.props.pageWrapId ? document.getElementById(this.props.pageWrapId) : window;
    this.pageWrap.addEventListener('scroll', this.handlePageScroll);
  }

  handlePageScroll() {
    const scrollTop = this.pageWrap.scrollTop || this.pageWrap.scrollY;

    if (!scrollTop || scrollTop <= 0) {
      if (this.state.hasScrolled) {
        this.setState({
          hasScrolled: false,
        });
      }
    } else {
      if (!this.state.hasScrolled) {
        this.setState({
          hasScrolled: true,
        });
      }
    }
  }

  render() {
    const { children } = this.props;

    return (
      <StyledPrimaryHeader
        style={{
          marginTop: this.state.hasScrolled ? -36 : 0,
        }}
      >
        {children}
      </StyledPrimaryHeader>
    );
  }
}

export default PrimaryHeader;
