import React, { useEffect, useRef } from 'react';
import ContentWrapper, { FormHeader, InputWrapper, RadioGroup, StyledForm, SelectInput, TextInput, ThankYouMessage } from './styles';
import Button from 'components/Button';
// import { set } from 'utils/localStorage';
import isBrowser from 'utils/isBrowser';
import countries from './countries';

interface Props {
  title: string;
}

interface State {
  formSubmitted: boolean;
}

export const RadioInput: React.StatelessComponent<{ id: string; title: string; list: string[] }> = ({ id, title, list }) => (
  <div className={`_field${id}`}>
    <FormHeader>{title}</FormHeader>
    <input type="hidden" name={`field[${id}][]`} value="~|" />
    <RadioGroup>
      {list.map((item: string, index) => (
        <div key={`form-input-${index}`} className="_checkbox-radio">
          <input id={`field_${id}${item}`} type="checkbox" name={`field[${id}][]`} value={item} />
          <label className="p5" htmlFor={`field_${id}${item}`}>
            <span />
            {item}
          </label>
        </div>
      ))}
    </RadioGroup>
  </div>
);


class SubscriptionForm extends React.Component<{ formId?: string }, State> {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://tradablebits.com/tbits-sdk.js";
    script.async = true;
    document.body.appendChild(script);
  }

  onSubmit(event) {
    event.preventDefault();
    const { TBITS } = window;
    if (TBITS) {
      const apiKey = "f0f99164-e15d-495c-8b80-73e567010ca3";
      let object = {
        'tag_name': [],
      };
      const data = new FormData(event.target);
      data.forEach((value, key) => {
        if(key == 'tag_name'){
          object[key].push(value);
        }else if(value) {
          object[key] = value;
        }
      })
      const email = data.get('email');
      TBITS.init(apiKey);

      TBITS.optInFan(object)
        .then(response => {
          this.setState({
            formSubmitted: true,
            loading: false
          });
          if (isBrowser()) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'subscribe',
              'formLocation': 'block'
            });
          }
        })
        .catch(error => console.log('Request failed', error));
    }
  }

  render() {
    const { formId } = this.props;
    const { formSubmitted } = this.state;
    const urlParams = isBrowser() ? new URLSearchParams(window.location.search) : null;
    return (
      <ContentWrapper id="scroll-container">
        {!formSubmitted && (
          <>
            <h3 className="h6 sign-up-header">Signup to recieve the latest news</h3>
            <StyledForm onSubmit={this.onSubmit}>
              <div className="_form-content">
                <TextInput type="hidden" name="is_subscribed" value={true} />
                <TextInput type="hidden" name="tag_name" value='sitgxr_website_form' />
                <InputWrapper>
                  <TextInput type="text" name="first_name" placeholder="First name *" />
                </InputWrapper>
                <InputWrapper>
                  <TextInput type="text" name="last_name" placeholder="Last name *" />
                </InputWrapper>
                <InputWrapper>
                  <TextInput type="email" name="email" placeholder="Email address *" required={true} />
                </InputWrapper>
                <InputWrapper>
                  <TextInput type="text" name="postal_code" placeholder="Postcode/Zip Code *" required={true} />
                </InputWrapper>
                <InputWrapper>
                  <SelectInput name="country" required={true}>
                    <option value="" disabled selected>Country *</option>
                    {countries.map((country) =>(
                      <option key={country.code} value={country.code}>{country.name}</option>  
                    ))}
                  </SelectInput>
                </InputWrapper>
                <div>
                  <FormHeader className="h6">
                    What devices do you use on a day to day basis?
              </FormHeader>
                  <RadioGroup>
                    <div>
                      <input
                        id={`${formId}_field_ios`}
                        type="checkbox"
                        name="tag_name"
                        value="device_ios"
                      />
                      <label className="p5" htmlFor={`${formId}_field_ios`}>
                        <span />
                    iOS
                  </label>
                    </div>
                    <div>
                      <input id={`${formId}_field_android`} type="checkbox" name="tag_name" value="device_android" />
                      <label className="p5" htmlFor={`${formId}_field_android`}>
                        <span />
                    Android
                  </label>
                    </div>
                    <div>
                      <input id={`${formId}_field_tablet`} type="checkbox" name="tag_name" value="device_tablet" />
                      <label className="p5" htmlFor={`${formId}_field_tablet`}>
                        <span />
                    Tablet
                  </label>
                    </div>
                    <div>
                      <input id={`${formId}_field_mac`} type="checkbox" name="tag_name" value="device_mac" />
                      <label className="p5" htmlFor={`${formId}_field_mac`}>
                        <span />
                    Mac
                  </label>
                    </div>
                    <div>
                      <input id={`${formId}_field_desktop`} type="checkbox" name="tag_name" value="device_desktop" />
                      <label className="p5" htmlFor={`${formId}_field_desktop`}>
                        <span />
                    Desktop PC
                  </label>
                    </div>
                  </RadioGroup>
                </div>
                <Button id={`${formId}submit`} className="_submit" type="submit" color="pink">
                Stay Updated
              </Button>
                <p className="p6">
                  By clicking submit you accept the{' '}
                  <a href="/terms-and-conditions" target="_blank">
                    Terms &amp; Conditions
              </a>{' '}
              and{' '}
                  <a href="/privacy" target="_blank">
                    Privacy Policy
              </a>
              .
            </p>
              </div>
            </StyledForm>
          </>
        )}
        {formSubmitted && (
          <ThankYouMessage className="h4">
            Thanks for subscribing.
          </ThankYouMessage>
        )}
      </ContentWrapper>
    )
  }
};

export default SubscriptionForm;
