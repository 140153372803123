import React from 'react';
import { SocialsType, IconSizeType } from 'types';
import IconLink from '../IconLink';
import StyledWrapper from './styles';

interface Props {
  size?: IconSizeType;
  links?: any;
}

const ArtistSocialLinks: React.StatelessComponent<Props> = ({ size, links = [] }) => (
  <StyledWrapper>
    {Object.keys(links).map((key, index) => (
      <>{links[key] && links[key] && <IconLink key={index} type={key} size={size} href={links[key]} />}</>
    ))}
  </StyledWrapper>
);

export default ArtistSocialLinks;
