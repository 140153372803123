import React from 'react';
import StyledSubTitle from './styles';

interface Props {
  size?: string;
  children: React.ReactNode;
}

const sizeClasses = {
  small: 'h7',
  medium: 'h5',
  large: 'h4',
};

const SubTitle: React.StatelessComponent<Props> = ({ size = 'large', children }) => (
  <StyledSubTitle className={sizeClasses[size] || 'large'}>{children}</StyledSubTitle>
);

export default SubTitle;
