import styled, { keyframes, css } from 'styled-components';
import noiseImage from 'assets/images/noise-texture-20-percent-opacity_black_retina.png';

const gradients = ['#fad5e2', '#fff3dd', '#fff7b8', '#fcefff', '#e6d8f4', '#b9e5ff'];

const noise = keyframes`
  0%, 100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%, 0%);
  }
  70% {
    transform: translate(0%, 15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
`;

export const GradientBackground = styled.span(
  ({ visible, index }) => `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 2s ease-in-out;
  opacity: ${visible ? 1 : 0};
  background-image: linear-gradient(180deg, ${gradients[index % gradients.length]} 0%, ${gradients[(index + 1) % gradients.length]} 100%);
`,
);

export const BackgroundNoise = styled.span(
  ({theme: { mobileView }}) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${mobileView} {
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      top: -100%;
      left: -100%;
      right: -100%;
      bottom: -100%;
      background-image: url(${noiseImage});
      opacity: 0.15;
    }
  `,
);
