import React from 'react';
import styled from 'styled-components';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

interface RotateProps {
  rotation?: string;
}

interface Props extends SvgIconType, RotateProps {}

const RotatedSvg = styled(StyledSvg)(
  ({ rotation = '315' }: RotateProps) => `
  transform: rotate(${rotation}deg);
`,
);

const ExternalLink: React.FunctionComponent<Props> = (props) => (
  <RotatedSvg viewBox="0 0 24 24" fill="currentColor" {...props}>
    <rect fill="none" />
    <polygon points="14.71 3.29 13.29 4.71 19.59 11 0 11 0 13 19.59 13 13.29 19.29 14.71 20.71 23.41 12 14.71 3.29" />
  </RotatedSvg>
);

export default ExternalLink;
