import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const ExternalLink: React.FunctionComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 24 16" {...props}>
    <path d="M23.89,1.83a.18.18,0,0,0-.2,0l-9.75,9.75a2.76,2.76,0,0,1-3.89,0L.31,1.87a.18.18,0,0,0-.2,0A.18.18,0,0,0,0,2V14a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A.18.18,0,0,0,23.89,1.83ZM11.12,10.56a1.26,1.26,0,0,0,1.76,0L22.57.87a.5.5,0,0,0,.12-.51C22.58,0,22.27,0,22,0H2c-.27,0-.58,0-.69.36a.5.5,0,0,0,.12.51Z" />
  </StyledSvg>
);

export default ExternalLink;
