import React from 'react';
import SpotifyPlayer from 'react-spotify-player';
import Block from 'components/Block';
import Title from 'components/Title';
import Copy from 'components/Copy';
import Cta from 'components/Cta';
import HtmlContent from 'components/HtmlContent';
import StyledSpotifyBlock, { ImageWrapper, ContentWrapper } from './styles';
import { BackgroundNoise } from 'layout/components/styles';
import { SpotifyIcon } from 'icons';
import { EmbedType, LinkType } from 'types';

interface Props {
  title: string;
  description: string;
  ctalink: LinkType;
  spotifyplaylist: EmbedType;
}

export const SpotifyBlock: React.StatelessComponent<Props> = ({ title, description, ctalink, spotifyplaylist }) => (
  <StyledSpotifyBlock>
    <Block>
      <ImageWrapper>
        <BackgroundNoise />
        {spotifyplaylist && <SpotifyPlayer uri={spotifyplaylist} />}
      </ImageWrapper>
      <ContentWrapper>
        <SpotifyIcon />
        <Title size="medium">{title}</Title>
        {description && (
          <Copy>
            <HtmlContent html={description} />
          </Copy>
        )}
        <Cta {...ctalink} />
      </ContentWrapper>
    </Block>
  </StyledSpotifyBlock>
);

export default SpotifyBlock;
