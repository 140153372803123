import isBrowser from './isBrowser';

export default (fn) => {
  if (isBrowser()) {
    if (document.readyState !== 'loading') {
      fn();
    } else if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', fn);
    }
  }
};
