import React from 'react';
import StyledTitle from './styles';
import { slugify } from 'utils/slugify';

interface Props {
  size?: string;
  children: React.ReactNode;
}

const Title: React.StatelessComponent<Props> = ({ size = 'large', children }) => <StyledTitle id={slugify(children)} size={size}>{children}</StyledTitle>;

export default Title;
