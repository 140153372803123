import React from 'react';
import isBrowser from 'utils/isBrowser';
import { AdSlot } from 'react-dfp';
import isMobile from 'utils/isMobile';
const Ad: React.FunctionComponent<any> = ({ width = 728, height = 90 }) => {
  if (!isBrowser()) {
    return null;
  }
  
  return (
    <div>
      {!isMobile() && 
      <div className="desktop-ads">
        <AdSlot targetingArguments={{ pagepos: '502', page: location.pathname, href: location.href }} sizes={[[728, 90]]} />
      </div>
      }
      {isMobile() && 
      <div className="mobile-ads">
        <AdSlot targetingArguments={{ pagepos: '502', page: location.pathname, href: location.href }} sizes={[[300, 250]]} />
      </div>
      }
    </div>
  );
};

export default Ad;
