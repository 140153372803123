import styled from 'styled-components';
import { ThemeType } from 'types';
import FillBlock, { ImageWrapper as FillBlockImageWrapper, ContentWrapper as FillBlockContentWrapper } from 'blocks/FillBlock/styles';
import StyledSvg from 'icons/styles';

export default styled(FillBlock)(
  ({
    theme: {
      colors,
      mobileView,
      utils: { rgba },
    },
  }: ThemeType) => `
`,
);

export const ContentWrapper = styled(FillBlockContentWrapper)(
  ({ theme: { mobileView } }: ThemeType) => `
  ${StyledSvg} {
    margin-top: -66px;
    margin-bottom: 35px;
    width: 31px;
    height: 31px;

    ${mobileView} {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }
`,
);

export const ImageWrapper = styled(FillBlockImageWrapper)(
  ({
    theme: {
      colors,
      mobileView,
      utils: { rgba },
    },
  }: ThemeType) => `
    background: linear-gradient(125deg, ${colors.pink} 17%, ${colors.pink} 22%, #c996b7 37%, #b6a3c1 51%, #a4b0cc 61%, ${
    colors.lightGreyBlue
  } 72%);

    ${mobileView} {
      padding-top: 470px;
    }

    iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      max-height: 90%;
      transform: translate(-50%, -50%);
      box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};
    }
  `,
);
