import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const MoodAgentIcon: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 25 25" {...props}>
    <path d="M2.61 15.98c-1.15 0-2.08-.88-2.08-1.96s.93-1.96 2.08-1.96 2.08.88 2.08 1.96-.93 1.96-2.08 1.96zm19.78 0c-1.15 0-2.08-.88-2.08-1.96s.93-1.96 2.08-1.96 2.08.88 2.08 1.96-.94 1.96-2.08 1.96zM7.62 18.06H7.5a2.02 2.02 0 01-2.02-2.02v-4.17c0-1.12.91-2.02 2.02-2.02h.12c1.12 0 2.02.91 2.02 2.02v4.17c0 1.11-.91 2.02-2.02 2.02zm4.94 2.44h-.12a2.02 2.02 0 01-2.02-2.02v-4.17c0-1.12.91-2.02 2.02-2.02h.12c1.12 0 2.02.91 2.02 2.02v4.17c0 1.11-.9 2.02-2.02 2.02zm4.94-2.87h-.12a2.02 2.02 0 01-2.02-2.02V6.52c0-1.12.91-2.02 2.02-2.02h.12c1.12 0 2.02.91 2.02 2.02v9.08c.01 1.12-.9 2.03-2.02 2.03z"/>
  </StyledSvg>
);

export default MoodAgentIcon;