import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const BurgerMenuIcon: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 24 24" {...props}>
    <path d="M0,17H24v2H0Zm0-6H24v2H0ZM0,5H24V7H0Z" fillRule="evenodd" />
  </StyledSvg>
);

export default BurgerMenuIcon;
