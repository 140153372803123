import React, { useState } from 'react';
import { differenceInDays, differenceInHours, differenceInMinutes, isFuture } from 'date-fns';
import Cta from 'components/Cta';
import Banner, {ModalAnnouncement, Content, FooterBanner, Title, SubscribeWrapper, ModalContentWrapper } from './styles';
import IconButton from 'components/IconButton';
import AnnouncementType from 'types/Announcement';
import SubscriptionForm from 'blocks/SubscribeBlock/Form';
import { useInterval } from 'hooks';
import { shouldShowAnnouncement } from 'localstorage';
import LogoImage from 'assets/images/splendour-xr-logo.png';

interface AnnouncementComponentType extends AnnouncementType {
  hide: () => void;
}

const Countdown: React.StatelessComponent<{ endDate: string }> = ({ endDate }) => {
  const getDistance = (end, start) => ({
    days: differenceInDays(end, start),
    hours: differenceInHours(end, start) % 24,
    minutes: differenceInMinutes(end, start) % 60,
  });

  const [distance, setDistance] = useState(getDistance(new Date(), endDate));
  useInterval(() => {
    setDistance(getDistance(endDate, new Date()));
  }, 1000);

  return (
    <span>
      {distance.days} days {distance.hours} hours {distance.minutes} mins
    </span>
  );
};

class FooterCTA extends React.PureComponent {
  state: any;
  props: any;

  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      formSubmitted: false,
      loading: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleFilter(e) {
    this.setState({ showForm: true });
  }
  
  onSubmit(event) {
    event.preventDefault();
    
    const data = new FormData(event.target);
    const email = data.get('email');
    
    this.setState({
      loading: true
    })
    fetch('https://secretsounds.activehosted.com/proc.php', {
      method: 'POST',
      body: data,
      mode: 'no-cors',
    })
      .then(response => {
        this.setState({
          formSubmitted: true,
          loading: false
        });
        if(isBrowser()) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
           'event': 'subscribe',
           'formLocation': 'announcement'
           });
         }
        if(email) {
          set('email', email);
          set('subscribed', true);
        }
        setTimeout(() => {
          this.setState({ formSubmitted: false });
        }, 5000);
      })
      .catch(error => console.log('Request failed', error));
  }

  render() {
    const { hide, footerCta } = this.props;
    const { formSubmitted, loading } = this.state;
    return (
      <FooterBanner>
        {footerCta && footerCta.triggerSubscribe && (
          <div>
            <Title className="h5">{footerCta.subscribeTitle && footerCta.subscribeTitle}</Title>
            <SubscribeWrapper>
              {formSubmitted && 
                <div dangerouslySetInnerHTML={{ __html: footerCta.subscribeThankyou ? footerCta.subscribeThankyou : "Thank you for subscribing. Check your emails for the next step." }} />
              }
              {!formSubmitted && 
                <>
                  {footerCta.subscribeCopy && <div dangerouslySetInnerHTML={{ __html: footerCta.subscribeCopy }}/> }
                  <SubscriptionForm onSubmit={this.onSubmit} loading={loading} />
                </>
              }
            </SubscribeWrapper>
          </div>
        )}
        {!footerCta.triggerSubscribe && footerCta.announcementcta && (
          <div>
            <a href={footerCta.announcementcta.url} target={footerCta.announcementcta.target} onClick={hide}>
              <Title>{footerCta.announcementcta.title}</Title>
            </a>
          </div>
        )}
        <IconButton type="close" onClick={hide} inverted={true} color="yellow"/>
      </FooterBanner>
    );
  }
}

const countdownInFuture = (countdown) => !countdown || !countdown.showCountdown || isFuture(countdown.endDate);

const AnnouncementBanner: React.StatelessComponent<AnnouncementComponentType> = ({
  content,
  cta = null,
  hide,
  pageTemplate,
  databaseId,
  title,
  announcementOptions,
  modalContent
}) => {
  switch (pageTemplate) {
    case 'footerCta':
      return (
        <>
          {shouldShowAnnouncement({ content, pageTemplate, databaseId }) && (
            <FooterCTA hide={hide} footerCta={announcementOptions} databaseId={databaseId} />
          )}
        </>
      );
    case 'modal':
      return (
        <>
        {shouldShowAnnouncement({ content, pageTemplate, databaseId, modalContent }) && (
          <ModalAnnouncement>
          
            <div>
              {modalContent?.replaceTitleWithLogo && <img src={LogoImage} alt='Splendour XR' style={{
                maxWidth: '80%',
                display: 'block',
                margin: '0 auto 16px'
              }} /> }
              {!modalContent?.replaceTitleWithLogo && <h4 className="h3">{title}</h4>}
              {modalContent?.modalContent && modalContent.modalContent.map((value, key) => {
                return <ModalContentWrapper key={`modal-content-${key}`}>
                  <Content dangerouslySetInnerHTML={{ __html: value.content }} />
                  <Cta {...value.cta} inverted={true} onClick={hide} color="blue"/>
                  </ModalContentWrapper>
              })}
              <Content dangerouslySetInnerHTML={{ __html: content }} />
              {announcementOptions && announcementOptions.announcementcta && (
                <Cta {...announcementOptions.announcementcta} inverted={true} onClick={hide} color="blue"/>
              )}
              <IconButton type="close" onClick={hide} inverted={true} color="transparent"/>
            </div>
          </ModalAnnouncement>
        )}
        </>
      )
    default:
      return (
        <>
          {shouldShowAnnouncement({ content, pageTemplate, databaseId }) && (
            <Banner>
              <div className="h7">
                <Content dangerouslySetInnerHTML={{ __html: content }} />
                {announcementOptions && announcementOptions.announcementcta && (
                  <Cta {...announcementOptions.announcementcta} inverted={true} onClick={hide} color='blue'/>
                )}
              </div>
              <IconButton type="close" onClick={hide} inverted={true} color="pink"/>
            </Banner>
          )}
        </>
      );
  }
};

export default AnnouncementBanner;
