import React from 'react';
import {
  AccordionBlock,
  AppBlock,
  ExploreLineupBlock,
  HtmlTextBlock,
  ImageBlock,
  FeaturedNewsBlock,
  SocialBlock,
  SpotifyBlock,
  VideosBlock,
} from '../';

const pageTemplates = {
  accordionBlock: AccordionBlock,
  appBlock: AppBlock,
  exploreLineupBlock: ExploreLineupBlock,
  htmlTextBlock: HtmlTextBlock,
  image: ImageBlock,
  featuredNews: FeaturedNewsBlock,
  socialBlock: SocialBlock,
  spotifyBlock: SpotifyBlock,
  videosBlock: VideosBlock
}

const ContentBlock: React.FunctionComponent = ({block, ctalink}) => {
  
  const contentBlock = pageTemplates[block.pageTemplate];
  if(!contentBlock) {
    return null;
  }
  const Block: React.StatelessComponent = contentBlock;
  return <Block ctalink={ctalink} title={block.title} {...block[block.pageTemplate]} />;
};

export default ContentBlock;
