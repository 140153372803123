import React from 'react';
import { HeroType } from 'types';
import { ReturnLink, HeroContainer, HeroWrapper, HeroAsssets } from './styles';

export const Hero: React.FunctionComponent<HeroType> = ({
  title,
  leadingParagraph,
  returnURL,
  returnLabel,
  center,
  children,
  sponsored,
}) => (
  <HeroWrapper>
    <HeroContainer center={center}>
      {returnURL && returnLabel && returnLabel != 'Explore' && (
        <ReturnLink className="p4" href={returnURL}>
          Back to {returnLabel}
        </ReturnLink>
      )}

      <h1 className="h0" dangerouslySetInnerHTML={{ __html: title }} />

      {leadingParagraph && <div className="p3" dangerouslySetInnerHTML={{ __html: leadingParagraph }} />}
      {sponsored && <span>Sponsored content</span>}
    </HeroContainer>
    {children && children}
    <HeroAsssets />
  </HeroWrapper>
);

export default Hero;
