import styled from 'styled-components';
import { ThemeType } from 'types';
import TitleWrapper from 'components/TitleWrapper/styles';
import Copy from 'components/Copy/styles';

export default styled.section(
  ({ theme: { mobileView } }: ThemeType) => `
    ${TitleWrapper} {
      margin-bottom: 26px;
    }

    ${Copy} {
      margin-top: 26px;
      margin-bottom: 32px;
    }

    ${mobileView} {
      margin-bottom: 80px;
    }
`,
);

export const ImageWrapper = styled.div(
  ({ theme: { colors, mobileView } }: ThemeType) => `
    padding-top: 56.34%;
    width: 100%;
    background-color: ${colors.black80};    
    position: relative;
    overflow: hidden;
    margin-bottom: 16px;    

    .post-image {
      position: absolute !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      transition: box-shadow 0.2s;
    }

    @media (pointer: none) {
      &:hover {
        box-shadow: none; 
      }
    }

    ${mobileView} {
      height: 96px;
      width: 96px;
      margin: 0;
      padding: 0;
    }
  `,
);

export const PostList = styled.div(
  ({ theme: { tabletView, mobileView } }: ThemeType) => `
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 48px;

    ${tabletView} {
      grid-template-columns: repeat(2, 1fr);
    } 

    ${mobileView} {
      grid-template-columns: 1fr;
    } 
  `,
);

export const PostLink = styled.a(
  ({ theme: { colors, mobileView } }: ThemeType) => `
    cursor: pointer;    

    &:hover {

      .post-title {
        transition: color 0.2s;
      }
    }    

    ${mobileView} {
      display: flex;
      flex-direction: row;

      .post-title {
        padding: 16px 0 0 16px;
      }
    }
`,
);
