import React from 'react';
import ReactPlayer from 'react-player';
import VideoWrapper from './styles';
import { EmbedType, ImageType } from 'types';
import { getSafe } from 'utils';

interface Props extends EmbedType {
  poster?: ImageType;
}

const getUrl = ({ url, sizes }: ImageType) =>
  typeof url === 'string'
    ? url
    : getSafe(() => url.localFile.childImageSharp.fixed.src)
    ? url.localFile.childImageSharp.fixed.src
    : sizes['hero-width']
    ? sizes['hero-width'].url
    : sizes.large
    ? sizes.large.url
    : sizes['medium-large']
    ? sizes['medium-large'].url
    : sizes.medium
    ? sizes.medium.url
    : sizes['medium-width']
    ? sizes['medium-width'].url
    : false;

const Video: React.FunctionComponent<Props> = ({ url, poster }) => (
  <VideoWrapper>
    <div className="video-player">
      <ReactPlayer
        url={url}
        light={false}
        className="react-player"
        controls={true}
        playing={false}
        height="100%"
        width="100%"
        FacebookConfig={{ appId: '563405637511416' }}
        YoutubeConfig={{ playerVars: { modestbranding: 1 } }}
      />
    </div>
  </VideoWrapper>
);
export default Video;
