import React from 'react';
import StyledCopy from './styles';

interface Props {
  children?: React.ReactNode;
}

const Copy: React.FunctionComponent<Props> = ({ children }) => <StyledCopy>{children}</StyledCopy>;

export default Copy;
