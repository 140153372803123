import styled from 'styled-components';
import TitleWrapper from 'components/TitleWrapper/styles';
import { ThemeType } from 'types';

export default styled.section(
  ({ theme: { mobileView } }: ThemeType) => `
  ${TitleWrapper} {
    margin-bottom: 26px !important;

    ${mobileView} {
      margin-bottom: 44px !important;
    }
  }
`,
);
