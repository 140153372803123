import styled from 'styled-components';
import IconLink from '../IconLink';
import ArrowRight from 'assets/svg/arrow-right.svg';
export default styled.div(
  ({ theme: { mobileView, spacing, fontSizes } }) => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: ${spacing.x8};
    height: 100%;
    width: 100%;
    overflow: auto;

    & > *:last-child {
      align-self: center;
    }

    ${mobileView} {
      display: block;
      text-align: left;
      padding-bottom: ${spacing.x2};

      .main-link { 
        font-size: ${fontSizes.md}; 
      }

      ${IconLink} {
        svg {
          height: 24px;
        }
      }
    }
  `,
);

export const MenuWrapper = styled.div(
  ({ theme: { spacing, mobileView } }) => `
      display: block;
      padding: ${spacing.x2} 0 ${spacing.x4};
  `,
);

export const MenuItem = styled.div(
  ({ theme: { mobileView, colors, spacing } }) => `
    text-align: left;
    padding: ${spacing.x2} 0;
    border-bottom: 1px solid ${colors.pink};
    &:last-of-type {
      border-bottom: none;
    }
    .main-link { 
      opacity: 1; 
    }
    .collapse-css-transition {
      transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
      overflow: hidden;
      border-top: 0 !important;
    }
    .sublinks {
      span {
        display: block;
        font-weight: 600;
        margin: ${spacing.x2} 0;
        font-size: 16px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  `,
);

export const Link = styled.a(
  ({ theme: { spacing, mobileView }, hasSublinks, open }) => `
    display: block;
    padding-bottom: 0;
    text-align: left;
    position: relative;
    transition: all 0.3s ease;
    ${hasSublinks && 
      `&:after {
        content: "";
        background-image: url(${ArrowRight});
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        height: 1em;
        width: 1em;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transform-origin: center center;
      }`
    }
    ${open && 
    `
    &:after {
      transform: translateY(-50%) rotate(90deg);
      transform-origin: center center;
    }
    `}
  `,
);
