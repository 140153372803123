import React, { useState } from 'react';
import { MENUS } from 'constants/layouts';

const { Consumer, Provider } = React.createContext({
    openSubscribeMenu: () => {
        return
    },
    openMainMenu: () => {
        return
    },
    openSearchMenu: () => {
        return
    },
    closeMenu: () => {
        return
    },
    openedMenu: false,
})

const SubscribeProvider: React.FunctionComponent<any> = ({ children }) => {
    const [openedMenu = false, openSubscribe] = useState();
    const toggleSubscribe = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'subscribeOpen'
        });
        openSubscribe(MENUS.SUBSCRIBE);
    }
    const toggleMenu = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'menuOpen'
        });
        openSubscribe(MENUS.MAIN);
    }
    const toggleSearch = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'searchOpen'
        });
        openSubscribe(MENUS.SEARCH);
    }
    const closeMenu = () => {
        openSubscribe(false);
    }
    return (<Provider
        value={{
            openedMenu,
            openSubscribeMenu : toggleSubscribe,
            openMainMenu: toggleMenu,
            openSearchMenu: toggleSearch,
            closeMenu,
        }}>
        {children}
    </Provider>)
}
export { SubscribeProvider };
export default Consumer;