export const HEADER_OFFSET = {
  default: 172,
  tablet: 172,
  mobile: 137,
};

export const ANNOUNCEMENT_OFFSET = {
  default: 60,
  tablet: 60,
  mobile: 80,
};
