import React from 'react';
import StyledTitleWrapper from './styles';

interface Props {
  children: React.ReactNode;
}

const TitleWrapper: React.StatelessComponent<Props> = ({ children }) => <StyledTitleWrapper>{children}</StyledTitleWrapper>;

export default TitleWrapper;
