import isBrowser from './isBrowser';

const HEADLESS_PARAM = 'headless';

export default () => {
  if (isBrowser()) {
    const url = new URL(location.href);
    const headless = url.searchParams.get(HEADLESS_PARAM);
    return headless !== null && headless !== 'false';
  }
};
