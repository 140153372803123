import styled from 'styled-components';
import { ThemeType } from 'types';
import StyledButton from 'components/Button/styles';

interface Props extends ThemeType {
  horizontal: boolean;
}

export default styled.div(
  ({ theme: { colors } }: ThemeType) => `
    ${StyledButton} {
      width: 48px;

      > span {
        padding-left: 14px;
        padding-right: 0;
        border-bottom-right-radius: 42px;
      }

      &:after {
        border-bottom-right-radius: 42px;
      }
    }
  `,
);

export const VerticalRect = styled.rect(
  ({ theme: { colors }, horizontal }: Props) => `
    ${horizontal && 'transform: rotate(90deg);'}
    -webkit-transition: -webkit-transform .3s linear;
    transition: transform .3s linear;
    transform-origin: center center;
    color: ${colors.black};
`,
);

export const StyledSvg = styled.svg(
  ({ theme: { colors } }: ThemeType) => `
    &, * {
      fill: ${colors.black};
    }
  `,
);
