import styled from 'styled-components';

export default styled.div(
  ({ theme: { colors, tabletView, mobileView } }) => `
    transform-origin: 100% 0;
    transform: rotate(45deg) translate(50%, 120px);
    background: ${colors.pink};
    width: 500px;
    padding: 5px 0;
    font-size: 21px;
    color: ${colors.black};
    ${tabletView} {
      font-size: 12px;
      transform: none;
      width: auto;
      padding: 0.2em 1em 0.3em;
      background: ${colors.black};
      color: ${colors.white};
    }
    ${mobileView} {
      display: none;
    }
    a {
      display: block;
      padding: 0.3em 0 0.35em;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      font-weight: 900;
      border-top: 2px solid ${colors.white};
      border-bottom: 2px solid ${colors.white};
      ${tabletView} {
        border: none;
        font-weight: 
      }
      &:hover {
        text-decoration: none;
      }
    }
`,
);
