import styled from 'styled-components';
import Cta from 'components/Cta/styles';
import Title from 'components/Title/styles';
import TitleWrapper from 'components/TitleWrapper/styles';
import { ThemeType } from 'types';
import { StyledBlockInner } from 'components/Block/styles';
import LeftAsset from 'assets/images/blue-fill-women-holding-hands.png';
import RightAsset from 'assets/images/pink-fill-woman-lying.png';

export default styled.section(
  ({ theme: { mobileView, spacing } }: ThemeType) => `
    margin-bottom: ${spacing.x6};
    ${mobileView} {
    }

    ${Cta} {

    }

    ${Title} {

    }

    ${TitleWrapper} {
      margin-bottom: 26px !important;

      ${mobileView} {
        margin-bottom: 44px !important;
      }
    }

    ${StyledBlockInner} {
      &:before {
        content: '';
        position: absolute;
        bottom: -108px;
        left: -184px;
        background-image: url(${LeftAsset});
        background-size: contain;
        background-repeat: no-repeat;
        width: 256px;
        height: 131px;
        z-index: 10;
        pointer-events: none;

        ${mobileView} {
          height: 100px;
          width: 194px;
          left: -48px;
          bottom: -77px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -150px;
        right: -239px;
        background-image: url(${RightAsset});
        background-size: contain;
        background-repeat: no-repeat;
        width: 286px;
        height: 168px;
        z-index: 10;
        pointer-events: none;

        ${mobileView} {
          width: 217px;
          height: 127px;
          right: -82px;
          bottom: -77px;
        }
      }
    }
`,
);
