import React, { useState, useEffect } from 'react';
import Collapse from '@kunukn/react-collapse';
import { FaqType } from 'types';
import OpenCloseToggle from '../OpenCloseToggle';
import { StyledItem, ContentBlock, TitleRow } from './styles';
import { slugify } from 'utils/slugify';
import isBrowser from 'utils/isBrowser';

const Item: React.StatelessComponent<FaqType> = ({ title, content }) => {
  if (!isBrowser()) {
    return null;
  }

  const [showAnswer, toggleAnswer] = useState(false);

  useEffect(() => {
    if (window.location.hash === `#${slugify(title)}`) {
      toggleAnswer(!showAnswer);
    }

    return;
  }, []);

  return (
    <StyledItem id={title && slugify(title)}>
      <TitleRow
        type="unstyled"
        open={showAnswer}
        onClick={() => {
          toggleAnswer(!showAnswer);
          window.history.pushState(null, '', title && '#' + slugify(title));
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: title }} />
        <OpenCloseToggle isOpen={showAnswer} />
      </TitleRow>
      <Collapse isOpen={showAnswer}>
        <ContentBlock dangerouslySetInnerHTML={{ __html: content }} />
      </Collapse>
    </StyledItem>
  );
};

export default Item;
