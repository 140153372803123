import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { StyledLocationSelector, StyledList } from './styles';



export const LanguageSelector: React.StatelessComponent<any> = ({language}) => (
  <StaticQuery
    query={graphql`
      query Languages {
        wp {
          languages {
            name
            homeUrl
          }
        }
      }
    `}
    render={({wp: {languages}}) => {
      if(languages.length > 1) {
      return (
      <StyledLocationSelector>
        <span>Language Select</span>
        <StyledList>
        {languages.map(({homeUrl, name}) => {
          if(homeUrl) {
            return <li key={`language-${name}`}><a href={homeUrl} >{name}</a></li>
          }
          return null
        })}
        </StyledList>
      </StyledLocationSelector>
    )}
    return null;
  }}
  />
);

export default LanguageSelector;
 