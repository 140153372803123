import React from 'react';
import Carousel, { CarouselBlock, CarouselSize } from 'components/Carousel';
import { PostType, ColumnBlockType } from 'types';
import StyledFeaturedNewsBlock from './styles';
import Block from 'components/Block';
import Title from 'components/Title';
import TitleWrapper from 'components/TitleWrapper';
import Cta from 'components/Cta';
import isMobile from 'utils/isMobile';

interface Props extends ColumnBlockType {
  posts?: PostType[];
  carouselSize?: CarouselSize;
  carousel: boolean;
}

export const FeaturedNews: React.StatelessComponent<Props> = ({ carouselSize = 'small', title, ctalink, posts, carousel = true }) => {
  if (!carousel) {
    posts = posts.filter((_, index) => index < 4);
  }

  return (
    <StyledFeaturedNewsBlock>
      <Block>
        <TitleWrapper>
          <Title>{title}</Title>
          {!isMobile() && <Cta {...ctalink} />}
        </TitleWrapper>
        <Carousel size={carouselSize} items={posts} carousel={carousel} />
        {isMobile() && <Cta {...ctalink} />}
      </Block>
    </StyledFeaturedNewsBlock>
  );
};

export default FeaturedNews;
