import styled from 'styled-components';

export default styled.a(
  ({ theme: { spacing, colors } }) => {
    const background = colors.pink;
    const color = colors.white;
    
    return `
    padding: ${spacing.x2} ${spacing.x4};
    line-height: 1;
    background-color: ${background};
    color: ${color};
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
    position: relative;
    transition: transform 0.2s cubic-bezier(0.42, 0.18, 0.11, 1.31);

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0.3em;
      left: 0.3em;
      height: 100%;
      width: 100%;
      border: 2px solid ${background};
      z-index: -1;
      transform: translate(0, 0);
      transition: transform 0.2s cubic-bezier(0.42, 0.18, 0.11, 1.31);
    }

    &:hover {
      text-decoration: none;
      &:after {
        transform: translate(2%, 2%);
      }
    }
`},
);
