import styled from 'styled-components';
import { ThemeType } from 'types';

interface Props extends ThemeType {
  open: boolean;
}

export default styled.div(
  ({ theme: { colors } }) => `
    border-top: 1px solid ${colors.blue};
  `,
);

export const StyledItem = styled.div(
  ({ theme: { colors } }) => `
    padding-left: 18px;
    padding-right: 22px;
    color: ${colors.white};
    fill: ${colors.white};
    border-bottom: 1px solid ${colors.blue};

    .collapse-css-transition {
      transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
      overflow: hidden;
      border-top: 0 !important;
    }

    & > * {
      border-top: 1px solid ${colors.blue};
    }
`,
);

export const TitleRow = styled.button(
  ({ theme: { colors, fontFamilies }, open }: Props) => `
    line-height: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    transition: all 0.3s ease;
    font-family: ${fontFamilies.default};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.19;
    letter-spacing: 0.76px;
    text-transform: uppercase;

    &:hover {
        span {
            color: ${colors.white70}
        }
    }

    span {
      text-align: left;
      flex: 1;
    }
`,
);

export const ContentBlock = styled.div(
  ({ theme: { colors } }) => `
    padding-top: 6px;
    padding-bottom: 29px;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.04px;

    a {
      text-decoration: underline;
      transition: all 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
`,
);
