import styled from 'styled-components';
import Cta from 'components/Cta/styles';
import Title from 'components/Title/styles';
import TitleWrapper from 'components/TitleWrapper/styles';
import { ThemeType } from 'types';
import { StyledBlockInner } from 'components/Block/styles';
import Woman from 'assets/images/pink-cloud-woman.png';
import Chair from 'assets/images/yellow-lounge-chair.png';

export default styled.section(
  ({ theme: { mobileView } }: ThemeType) => `
    ${mobileView} {
      margin-bottom: 214px;
    }

    ${Cta} {

    }

    ${Title} {

    }

    ${TitleWrapper} {
      margin-bottom: 26px !important;

      ${mobileView} {
        margin-bottom: 44px !important;
      }
    }

    ${StyledBlockInner} {
      &:before {
        content: '';
        position: absolute;
        bottom: -84px;
        left: -173px;
        background-image: url(${Woman});
        background-size: contain;
        background-repeat: no-repeat;
        width: 231px;
        height: 157px;
        z-index: 10;
        pointer-events: none;

        ${mobileView} {
          height: 119px;
          width: 174px;
          left: -22px;
          bottom: -153px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -89px;
        right: -132px;
        background-image: url(${Chair});
        background-size: contain;
        background-repeat: no-repeat;
        width: 187px;
        height: 160px;
        z-index: 10;
        pointer-events: none;

        ${mobileView} {
          width: 142px;
          height: 122px;
          right: 10px;
          bottom: -154px;
        }
      }
    }
`,
);
