import styled from 'styled-components';

import StyledContentType from './types';

export const Content = styled.div(
  ({ isFirstSection, pageHasAnnouncement, theme: { mobileView, spacing } }: StyledContentType) => `
    position: relative;
    z-index: 14;
    display: flex;
    flex-wrap: no-wrap;
    margin: auto;
    justify-content: center;
    align-items: flex-start;
    padding-top: ${isFirstSection ? `${pageHasAnnouncement ? '120' : '60'}px` : '0'};
    ${mobileView} {
      max-width: 100%;
      flex-wrap: wrap;
      
      & > * {
        flex: 1 100%;
      }
    }
`,
);

export default Content;

export const PageContainer = styled.div(
  ({ hasSidebar, theme: { mobileView, spacing } }) => `
  display: flex;
  width: 1216px;
  margin: auto;
  max-width: 100%;
  > ${Content} {
    max-width: ${hasSidebar ? `calc(100% - (252px + ${spacing.x7}))` : '100%'  };
  }
  ${mobileView} {
    > ${Content} {
      max-width: 100%;
    }
    flex-direction: column;
    & > * {
      flex: 1 100%;
    }
  }
`, 
)