import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const ArrowLeft: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 9 14" {...props}>
    <path fill-rule="evenodd" d="M0 7l9 6.5V.5z" clip-rule="evenodd"/>
  </StyledSvg>
);

export default ArrowLeft;
