import React from 'react';
import { ArtistType } from 'types';
import Row, { Session, Title, Sessions, NextArrow } from './styles';
import { ArrowRight } from 'icons';
import { format } from 'date-fns';

interface Props extends ArtistType {
  selectArtist: any;
  fade: boolean;
}

const LineupRow: React.FunctionComponent<Props> = ({
  databaseId,
  title,
  artistDetails: { sessionArtistRelationship },
  selectArtist,
  fade,
}) => {
  const handleClick = () => {
    selectArtist(databaseId)};
  return (
    <Row onClick={handleClick}>
      <Title className="h5" fade={fade}>
        {title}
      </Title>
      <Sessions>
        {sessionArtistRelationship &&
          sessionArtistRelationship.map(({ sessionDetails: { sessionDate, startTime } }) => (
            <Session>{`${sessionDate ? format(sessionDate, 'ddd') : ''} ${startTime ? format(startTime, 'HH:mm') : ''}`}</Session>
          ))}
      </Sessions>
      <NextArrow size="medium" color="whitePink" inverted={true}>
        <span>
          <span><ArrowRight /></span>
        </span>
      </NextArrow>
    </Row>
  );
};

export default LineupRow;
