import styled from 'styled-components';
import { HtmlImage, GatsbyImage, FallbackImage } from 'components/Image/styles';
import Title from 'components/Title/styles'
export const GalleryWrapper = styled.div(
  ({ theme: {
    colors,
    easing,
    spacing,
    utils: { rgba },
  }}) => `
  position: relative;
  padding: 45px;
  ${Title} {
    margin-bottom: 32px;
    color: ${colors.black};
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: red;
    border-top-right-radius: 80px;
    box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};
    background-color: ${colors.blue};
    color: ${colors.black};
    z-index: -1;
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-slide {
    &:nth-of-type(3n - 1) {
      ${GalleryImage} {
        &:after {
          background-color: ${colors.cream};
        }
      }
    }

    &:nth-of-type(3n) {
      ${GalleryImage} {
        &:after {
          background-color: ${colors.lime};
        }
      }
    }
    
  }
  `,
);

export const GalleryImage = styled.div(
  ({ theme: {
    colors,
    easing,
    spacing,
    mobileView,
    utils: { rgba },
  }}) => `
  display: block;
  position: relative;
  margin-right: ${spacing.x8};
  
  ${HtmlImage},
  ${GatsbyImage},
  ${FallbackImage} {
    border-top-left-radius: 80px;
    position: relative;
    display: block;
    z-index: 5;
    transition: all 0.2s ${easing};
    max-width: 432px;
    max-height: 432px;
    height: auto;
    width: auto;
    ${mobileView} {
      max-width: 100%;
    }
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    border-top-left-radius: 80px;
    background-color: ${colors.pink};
    transition: all 0.2s ${easing};
    transform: translate(12px, 12px);
    box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};
  }

  &:nth-of-type(3n - 1) {
    &:after {
      background-color: ${colors.fadedPurple};
    }
  }

  &:nth-of-type(3n) {
    &:after {
      background-color: ${colors.sand};
    }
  }

  &:hover {
    ${HtmlImage},
    ${GatsbyImage},
    ${FallbackImage}  {
      transform: translate(6px, 6px);
    }

    &:after {
      transform: translate(6px, 6px);
      box-shadow: 10px 10px 14px ${rgba(colors.eggplant, 0.08)};
    }

    &:before {
      opacity: 1;
      transform: translate(6px, 6px);
    }
  }
`,
);
