import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const CloseIcon: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 24 24" {...props}>
    <path
      d="M12,10.59,17,5.64l1.41,1.41-4.95,5L18.36,17,17,18.36,12,13.41,7.05,18.36,5.64,17l5-4.95L5.64,7.05,7.05,5.64Z"
      fillRule="evenodd"
    />
  </StyledSvg>
);

export default CloseIcon;
