import styled from 'styled-components';
import StyledAnchor from '../IconLink/styles';
import HtmlContent from 'components/HtmlContent';
export default styled.div(
  ({ theme: { mobileView, spacing } }) => `
    width: 100%;
    margin: 0 auto;
    padding-bottom: ${spacing.x8};

    ${mobileView} {
      border-bottom-width: 0;
    }

    &:not(:last-child) {
      padding-bottom: ${spacing.x6};
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: black;
    }
`,
);

export const ContentWrapper = styled.div(
  ({ theme: { mobileView, spacing } }) => `
    display: flex;
    flex-direction: column;

    ${mobileView} {
      flex-direction: column-reverse;
      padding-top: ${spacing.x3};
    }
`,
);

export const Title = styled.h3(
  ({ theme: { mobileView, spacing, fontSizes, fontFamilies } }) => `
    padding-bottom: ${spacing.x2};
    font-family: ${fontFamilies.heading};
    text-transform: uppercase;
    ${mobileView} {
      font-size: ${fontSizes.lg} !important;
    }
`,
);

export const LeadingContent = styled.div`
  width: 100%;
  height: auto;
`;

export const SocialsWrapper = styled.div(
  ({ theme: { mobileView, colors, spacing } }) => `
    padding: ${spacing.x3} 0 0;

    ${StyledAnchor} {
      svg {
        margin-bottom: ${spacing.x3};

        ${mobileView} {
          fill: ${colors.white};
        }
      }
    }
`,
);

export const ArtistDetail = styled.div(
  ({ theme: { mobileView, colors, spacing, fontSizes } }) => `
  &::not(:last-child) {
    padding-bottom: ${spacing.x2};
  }
  color: ${colors.pink};
  ${mobileView} {
    font-size: ${fontSizes.xs} !important;
  }
`,
);

export const Bio = styled.div(
  ({ theme: { mobileView, fontSizes } }) => `

  ${mobileView} {
    font-size: ${fontSizes.xxs} !important;
  }
`,
);
