import styled from 'styled-components';
import { ThemeType } from 'types';
import Title from 'components/Title/styles';
import WhiteTear from 'assets/images/hero-tear-cream.png';

export default styled.section(
  ({ theme: { mobileView, spacing }, styling = null }: ThemeType) => `
    ${Title} {
      margin-bottom: 31px;
    }
`,
);

export const VideosWrapper = styled.div(
  ({ theme: { mobileView, spacing, colors, utils: { rgba }, } }: ThemeType) => `
    display: flex;
    color: ${colors.black};
    .main-video {
      flex: 1 auto;
      margin-right: ${spacing.x6};
      
      ${mobileView} {
        width: 100%;
        margin-right: 0;
        margin-bottom: ${spacing.x10};
      }          
      .video-player, 
      .video-poster {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        width: 100%;
        background-color: ${colors.black};
        
        & > *:first-child {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .video-player {
        box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};
      }

      h3 {
        text-transform: uppercase;
        max-width: 664px;
        margin-top: -${spacing.x2};
        margin-left: ${spacing.x2};
        margin-right: ${spacing.x2};
        position: relative;
        z-index: 5;
        padding: 0.3em 0.5em 0.6em;
        background-color: ${rgba(colors.eggplant, 0.08)};
        box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};
        ${mobileView} {
          font-size: 20px;
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 20px;
          background-color: ${colors.cream};
          z-index: -1;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          height: 20px;
          left: 0;
          right: 0;
          bottom: 1px;
          background-image: url(${WhiteTear});
          background-size: 293px 20px;
          z-index: -1;
        }
      }
    }

    .video-playlist {
      display: flex;
      flex-direction: column;
      flex: 0 192px;
      justify-content: flex-start;
      
      .video-link {
        cursor: pointer;
        h4 {
          z-index: 5;
          font-weight: 500 !important;
          margin-top: -${spacing.x2};
          margin-bottom: ${spacing.x2};
          margin-left: ${spacing.x1};
          margin-right: ${spacing.x1};
          position: relative;
          text-transform: uppercase;
          padding: 0.3em 0.5em 0.6em;
          background-color: ${rgba(colors.eggplant, 0.08)};
          box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};
          ${mobileView} {
            font-size: 20px;
          }
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 20px;
            background-color: ${colors.cream};
            z-index: -1;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            height: 20px;
            left: 0;
            right: 0;
            bottom: 1px;
            background-image: url(${WhiteTear});
            background-size: 293px 20px;
            z-index: -1;
          }
        }
        
        .video-poster {
          transition: transform  0.2s;        
          
          iframe {
            min-width: auto !important;
            min-height: 100px;
            box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};
          }
          
          .react-player {
            pointer-events: none;
          }

          &:hover {
            transform: scale(1.05);
          }          
        }
      }

      ${mobileView} {
        min-width: 100%;
        width: auto;
        flex-direction: row;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        justify-content: flex-start;
        flex: auto;
        
        .video-link {          
          min-width: 188px;
          margin-right: ${spacing.x3};          
        }

        h4 {
          font-size: 14px;
        }
      }
      
      .active {
        display: none;
      }
    }
    
    ${mobileView} {
      flex-direction: column;
    }
  `,
);
