import React from 'react';
import { AccordionType } from 'types';
import Block from 'components/Block';
import TitleWrapper from 'components/TitleWrapper';
import Title from 'components/Title';
import Cta from 'components/Cta';
import Accordion from 'components/Accordion';
import StyledAccordionBlock from './styles';

interface Props {
  title: string;
  faqs?: AccordionType[];
  items?: AccordionType[];
}

export const AccordionBlock: React.FunctionComponent<Props> = ({ title, items, faqs, ctalink }) => {
  items = items || faqs;

  return (
    <StyledAccordionBlock>
      <Block>
        <TitleWrapper>
          <Title>{title}</Title>
          <Cta {...ctalink} />
        </TitleWrapper>
        <Accordion title={title} items={items} />
      </Block>
    </StyledAccordionBlock>
  );
};

export default AccordionBlock;
