import styled from 'styled-components';
import ColumnBlock from 'blocks/Block';
import Theme from 'types/Theme';
import HeroLeft from 'assets/images/hero-l.png';
import HeroRight from 'assets/images/hero-r.png';

interface ContainerProps extends Theme {
  center?: boolean;
}

interface HeroWrapperProps extends Theme {
  center?: boolean;
}

export const HeroWrapper = styled(ColumnBlock)(
  ({ theme: { spacing, mobileView } }: HeroWrapperProps) => `
    text-align: left;
`,
);
export const ReturnLink = styled.a(
  ({ theme: { spacing}}) => `
  display: block;
  line-height: 1;
  margin-bottom: ${spacing.x1};
  text-transform: uppercase;
  text-align: center;
  svg {
    height: 1.5em;
    width: 1.5em;
  }
`);
export const HeroAsssets = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 1200px;
  max-width: 100%;
  transform: translateX(-50%);
  pointer-events: none;
  &:after, &:before {
    content: '';
    position: absolute;
    top: 50%;
    display: block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 15vw;
  }
  &:before {
    background-image: url(${HeroLeft});
    width: 330px;
    padding-top: 62%;
    right: 100%;
    transform: translate(50%, -50%);
  }
  &:after {
    background-image: url(${HeroRight});
    width: 330px;
    padding-top: 73%;
    left: 100%;
    transform: translate(-50%, -50%);
  }
`;
export const HeroContainer = styled.div(
  ({ theme: { spacing, mobileView, fontFamilies }, center }: ContainerProps) => `
    position: relative;
    display: block;
    width: 100%;
    max-width: 640px;
    margin: auto;
    z-index: 10;
    padding: ${spacing.x6} 0;
    ${center && 'text-align: center;'}

    h1 {
      font-family: ${fontFamilies.heading};
      margin: 0 0 ${spacing.x1} 0;
      text-transform: uppercase;
    }

    p {
      margin: 0 0 ${spacing.x2} 0;
      line-height: 1.2;
    }

    ${mobileView} {
      max-width: 80%;
      padding: ${spacing.x6} 0 ${spacing.x2};
      a {
        margin-bottom: ${spacing.x3}
      }

      h1 {
        margin: 0 0 ${spacing.x2} 0;
        font-size: 40px;
      }

      p {
        line-height: 1.6;
      }
    }
`,
);
