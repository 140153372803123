import styled from 'styled-components';
import { default as ImageComponent } from 'components/Image';
import { StyledBlockInner } from 'components/Block/styles';
import Plant from 'assets/images/red-fill-pandanas.png';
import Title from 'components/Title/styles';
import { ThemeType } from 'types';

interface Props extends ThemeType {
  hasMobile: boolean;
}

export default styled.section(
  ({ theme: { mobileView, spacing }, styling = null }: ThemeType) => `
    text-align: center;
    
    ${StyledBlockInner} {
      ${!styling && `max-width: 952px;`}
    }
    ${Title} {
      margin-bottom: 31px;
    }
`,
);

export const Media = styled.section(
  ({
    theme: {
      colors,
      mobileView,
      spacing,
      utils: { rgba },
    },
    styling = null
  }) => `
    position: relative;
    margin-bottom: ${spacing.x4};
    a {
      display: block;
      ${!styling && `border-top-left-radius: 80px;`}
      overflow: hidden;
      ${!styling && `box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};`}

      ${mobileView} {
        border-top-left-radius: 57px;
        height: auto;
      }
    }
    ${!styling && 
      `&:after {
        content: '';
        position: absolute;
        bottom: -108px;
        right: -102px;
        background-image: url(${Plant});
        background-size: contain;
        background-repeat: no-repeat;
        width: 230px;
        height: 235px;
        z-index: 10;
        pointer-events: none;

        ${mobileView} {
          width: 148px;
          height: 150px;
          bottom: -79px;
          right: -54px;
        }
      }`
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    ${mobileView} {
      margin-bottom: 22px;
      min-height: 0;
      width: 100%;
      ${!styling && `border-top-left-radius: 58px;`}
    }
`,
);

export const DesktopImage = styled(ImageComponent)(
  ({ theme: { mobileView }, hasMobile }: Props) => `
  display: block;
  ${
    hasMobile
      ? `
    ${mobileView} {
      display: none;
    }
  `
      : ''
  }

`,
);

export const MobileImage = styled(ImageComponent)(
  ({ theme: { mobileView } }) => `
  display: none;

  ${mobileView} {
    display: block;
  }
`,
);
