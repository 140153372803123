import styled from 'styled-components';
import { ThemeType } from 'types';
import tick from 'assets/svg/tick-box.svg';
import Button from 'components/Button/styles';

export default styled.div(
  ({ theme: { mobileView, spacing } }: ThemeType) => `
  @import url('//d3rxaij56vjege.cloudfront.net/pikaday/1.3.3/pikaday.css');
  padding: ${spacing.x3} 0;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  ${mobileView} {
    padding: ${spacing.x3};
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  /* Change auto compile styling in chrome */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: inherit;
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 30px rgba(0,0,0,0); inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`,
);

export const StyledForm = styled.form(
  ({ theme: { mobileView, spacing } }: ThemeType & { novalidate?: boolean }) => `
  padding-top: ${spacing.x3};
  & > * { outline:0; }
  ${mobileView} {
    padding-bottom: 10vh;
  }
  ${Button} {
    margin-top: ${spacing.x2};
    margin-bottom: ${spacing.x2};
  }
  textarea { resize:none; }

  input[type="text"]._has_error, textarea._has_error { border-bottom: #f37c7b 2px solid; color: #f37c7b; }

  input[type="checkbox"]._has_error { outline:#f37c7b 1px solid; }

  ._error {
    display:block;
    position:absolute;
    font-size:13px;
    z-index:10000001;
  }
  ._error._above {
    padding-bottom: 4px; 
    top: -${spacing.x2};
    right: 0;
  }
  ._error._below {
    padding-top:4px;
    top:100%;
    right:0;
  }
  ._error._above ._error-arrow { 
    bottom:0; right:15px; border-left:5px solid transparent; border-right:5px solid transparent; border-top:5px solid #f37c7b;
  }
  ._error._below ._error-arrow { top:0; right:15px; border-left:5px solid transparent; border-right:5px solid transparent; border-bottom:5px solid #f37c7b; }
  ._error-inner { padding:8px 12px; background-color:#f37c7b; font-size:13px; font-family:arial, sans-serif; color:#fff; text-align:center; text-decoration:none; -webkit-border-radius:4px; -moz-border-radius:4px; border-radius:4px; }
  ._error-inner._form_error { margin-bottom:5px; text-align:left; }
  ._error-inner._no_arrow { margin-bottom:10px; }
  ._error-arrow { position:absolute; width:0; height:0; }
  ._error-html { margin-bottom:10px; }

 @media all and (min-width:320px) and (max-width:667px) {
   ::-webkit-scrollbar {
     display:none;
    }
  }
 }`,
);

export const InputWrapper = styled.div`
  position: relative;
`;

export const TextInput = styled.input(
  ({ theme: { colors, fontSizes, spacing } }) => `
  &::placeholder {
    color: ${colors.black};
  }
  outline:0;
  letter-spacing: -0.04px;
  color: ${colors.black};
  width: 100%;
  background-color: ${colors.white};
  border: none;
  font-size: ${fontSizes.xxs};
  padding: ${spacing.x2};
  margin-bottom: ${spacing.x2};
`,
);

export const SelectInput = styled.select(
  ({ theme: { colors, fontSizes, spacing } }) => `
  &::placeholder {
    color: ${colors.black};
  }
  appearance: none;
  border-radius: 0;
  outline:0;
  letter-spacing: -0.04px;
  color: ${colors.black};
  width: 100%;
  background-color: ${colors.white};
  border: none;
  font-size: ${fontSizes.xxs};
  padding: ${spacing.x2};
  margin-bottom: ${spacing.x2};
  background-repeat: no-repeat;
  background-position: center right .6875em;
  background-size: 1em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='visible' viewBox='0 0 12 7'%3E%3Cpath fill-rule='evenodd' d='M1.4 0L6 4.3 10.6 0 12 1.3 6 7 0 1.3z' clip-rule='evenodd'/%3E%3Cpath fill-rule='evenodd' d='M0 1.3L6 7l6-5.7L10.6 0 6 4.3 1.4 0 0 1.3z' clip-rule='evenodd'/%3E%3C/svg%3E");
`,
);

export const FormHeader = styled.h6(
  ({ theme: { spacing } }) => `
  padding: ${spacing.x4} 0 ${spacing.x3};
  text-transform: uppercase;
  font-size: 16px;
`,
);

export const RadioGroup = styled.div(
  ({ theme: { spacing, colors } }) => `
  & > *:not(:last-child) {
    padding-bottom: ${spacing.x1};
  }

  input {
    display: none;
    -webkit-appearance: none;
    webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
  }

  input[type="checkbox"] + label span {
    margin-right: ${spacing.x2};
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border: none;
    background-color: ${colors.white};
  }

  input[type="checkbox"]:checked + label span {
    background-image: url(${tick});
    background-position: center;
    background-repeat: no-repeat;
  }
  
`,
);

export const ThankYouMessage = styled.div`
  
`;
