import React from 'react';
import Block from 'components/Block';
import Title from 'components/Title';
import SubTitle from 'components/SubTitle';
import TitleWrapper from 'components/TitleWrapper';
import Cta from 'components/Cta';
import { TicketBlockType } from 'types';
import StyledTicketBlock, { Tickets } from './styles';
import { useInView } from 'react-intersection-observer';
import isBrowser from 'utils/isBrowser';
import isMobile from 'utils/isMobile';
import { TicketCategoryBlock } from 'blocks';

export const TicketBlock: React.FunctionComponent<TicketBlockType> = ({ title, subTitle, tickets, ctalink }) => {
  if (!isBrowser()) {
    return null;
  }

  const [ref, onScreen] = useInView({ threshold: 0.75 });

  return (
    <StyledTicketBlock>
      <Block>
        <TitleWrapper>
          <Title>{title}</Title>
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          {!isMobile() && <Cta {...ctalink} />}
        </TitleWrapper>
        <TicketCategoryBlock tickets={tickets} />  
        {isMobile() && <Cta {...ctalink} />}
      </Block>
    </StyledTicketBlock>
  );
};

export default TicketBlock;
