import React from 'react';
import { FaqType } from 'types';
import StyledAccordion from './styles';
import { slugify } from 'utils/slugify';
import AccordionItem from './Item';

interface Props {
  title: string;
  items: FaqType[];
}

const Accordion: React.StatelessComponent<Props> = ({ title, items }) => (
  <StyledAccordion id={title && slugify(title)}>
    {items && items.map((data, index) => <AccordionItem key={index} {...data} />)}
  </StyledAccordion>
);

export default Accordion;
