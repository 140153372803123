import React from 'react';
import { ImageType, LinkType } from 'types';
import Block from 'components/Block';
import Title from 'components/Title';
import Cta from 'components/Cta';
import TitleWrapper from 'components/TitleWrapper';
import StyledImageBlock, { DesktopImage, MobileImage, Media } from './styles';
import isMobile from 'utils/isMobile';
import isImageValid from 'utils/isImageValid';

export interface Props {
  title: string;
  ctalink: LinkType;
  image: ImageType;
  mobileImage?: ImageType;
  imagelink?: LinkType;
  removeStyling ?: boolean;
}

export const Image: React.StatelessComponent<Props> = ({ title, ctalink, image, mobileImage, imagelink, removeStyling }) => {
  const hasMobile = isImageValid(mobileImage);
  return (
    <StyledImageBlock styling={removeStyling}>
      <Block>
        {title &&
          <TitleWrapper>
            <Title>{title}</Title>
            {!isMobile() && title && <Cta {...ctalink} />}
          </TitleWrapper>
        }
        <Media styling={removeStyling}>
          {imagelink && imagelink.url ? (
            <a href={imagelink.url} target={imagelink.target}>
              <DesktopImage {...image} hasMobile={hasMobile} />
              {hasMobile && <MobileImage {...mobileImage} />}
            </a>
          ) : (
            <>
              <DesktopImage {...image} hasMobile={hasMobile} />
              {hasMobile && <MobileImage {...mobileImage} />}
            </>
          )}
        </Media>
        {(isMobile() || !title) && <Cta {...ctalink} />}
      </Block>
    </StyledImageBlock>
  );
};

export default Image;
