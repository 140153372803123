import styled from 'styled-components';
import { ThemeType } from 'types';

export const StyledBlock = styled.div``;

export const StyledBlockInner = styled.div(
  ({ theme: { width, mobileView } }: ThemeType) => `
    position: relative;
    max-width: ${width.tablet};
    margin: 0 auto;

    ${mobileView} {
      padding: 0 20px;
    }
  `,
);
