import React, { useState } from 'react';

const { Consumer, Provider } = React.createContext({
  artist: null,
  updateArtist: () => {
    return;
  },
});

const ArtistProvider: React.FunctionComponent<any> = ({ children }) => {
  const [artist, updateArtist] = useState();

  return (
    <Provider
      value={{
        artist,
        updateArtist,
      }}
    >
      {children}
    </Provider>
  );
};

export { ArtistProvider };

export default Consumer;
