import styled from 'styled-components';
import Title from 'components/Title/styles';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  ${Title} {
    flex: 1;
  }
`;

export default TitleWrapper;
