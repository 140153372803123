import AnnouncementType from '../types/Announcement';
import { getLastDismissedAnnouncement as get, setLastDismissedAnnouncement as set } from '../utils/localStorage';

export const hideAnnouncement = (announcement: AnnouncementType) => setLastDismissedAnnouncement(announcement);

export const getLastDismissedAnnouncement = () => {
  const announcementValue = get();

  if (!announcementValue) {
    return null;
  }

  return announcementValue;
};

export const setLastDismissedAnnouncement = (announcement: AnnouncementType) => {
  set(JSON.stringify(announcement));
};

export const shouldShowAnnouncement = (announcement: AnnouncementType) => {
  if (!announcement) {
    return false;
  }

  const lastDismissedAnnouncement = getLastDismissedAnnouncement();

  return !!(!lastDismissedAnnouncement || announcement.databaseId !== lastDismissedAnnouncement.databaseId);
};
