import React from 'react';
import StyledWrapper, { StyledSvg, VerticalRect } from './styles';
import Button from 'components/Button';

interface Props {
  isOpen: boolean;
}

const OpenCloseToggle: React.FunctionComponent<Props> = ({ isOpen }) => (
  <StyledWrapper>
    <Button inverted={true} color="whiteLime">
      <StyledSvg width="12" height="12" viewBox="0 0 36 36">
        <rect height="12" width="36" x="0" y="12" />
        <VerticalRect horizontal={isOpen} width="12" height="36" x="12" y="0" />
      </StyledSvg>
    </Button>
  </StyledWrapper>
);

export default OpenCloseToggle;
