import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import LineupComponent from 'components/Lineup';
import { LinkType, LineupType } from 'types';
import Cta from 'components/Cta';
import StyledBlock from './styles';

interface Props extends LineupType {
  lineupPageSlug: string;
  ctalink?: LinkType;
}

export const Lineup: React.FunctionComponent<Props> = ({ ctalink, title }) => (
  <StaticQuery
    query={graphql`
      query Artists {
          allWpArtist(filter: {hideArtist: {hideArtistFromLineup: {ne: false}}}) {
            nodes {
              id
              link
              title
              hideArtist {
                hideArtistFromLineup
              }
            }
          }
      }
    `}
    render={(data) => {
      const filteredArtists = data.wordpress.artists.nodes
        .filter((artist) => !artist.hideArtist.hideArtistFromLineup)
        .sort((a, b) => a.menuOrder - b.menuOrder);
      return (
        <>
          {filteredArtists && (
            <StyledBlock title={title} ctaStyling={true}>
              <LineupComponent artists={filteredArtists} />
              {ctalink && <Cta {...ctalink} color="blackWhite" />}
            </StyledBlock>
          )}
        </>
      );
    }}
  />
);

export default Lineup;
