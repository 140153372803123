import styled, { css, keyframes } from 'styled-components';
import headerImage from 'assets/images/hero-tear-blue.png';
import headerShadow from 'assets/images/header-background-shadow.png';
import IconButton from 'components/IconButton';
import Cta from 'components/Cta/styles';
import TicketButton from 'components/TicketButton/styles';
import Logo from 'components/Logo/styles';

export const HeaderWrapper = styled.div(
  ({ theme: { header, tabletView } }) => `
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
  height: ${header.height};
  ${TicketButton} {
    position: absolute;
    top: 100%;
    right: 0;
    pointer-events: all;
    ${tabletView} {
      display: none;
    }
  }
  ${Cta} {
    margin-right: 1em;
  }
`,
);

export const MainHeader = styled.header(
  ({ theme: { colors, header } }) => `
    width: 100%;
    height: ${header.height};
    background-position: 50% 0%;
    background-image: url(${headerImage});
    background-size: 812px 63px;
    color: ${colors.black};
    position: sticky;
    top: 0;
    z-index: 300;
    transition: margin-top 0.3s ease-in-out 0.3s;
`,
);

export const HeaderShadow = styled.header(
  ({ theme: { colors, header } }) => `
    pointer-events: none;
    width: calc(100% + 16px);
    height: ${header.height};
    background-position: 50% 0%;
    background-image: url(${headerShadow});
    background-size: 812px 63px;
    color: ${colors.black};
    position: absolute;
    top: 28px;
    left: -8px;
    z-index: 0;
    opacity: 0.08;
    transform: translateX(8px);
`,
);

export const HeaderContent = styled.div(
  ({ theme: { spacing, mobileView, header } }) => `
    height: ${header.height};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${spacing.x6};
    max-width: 1440px;
    margin: auto;
    ${mobileView} {
      padding: ${spacing.x2} ${spacing.x2};
    }
`,
);

export const MenuWrapper = styled.div(
  ({ theme: { spacing, tabletView } }) => `
    display: flex;
    align-items: center;
    height: 100%;

    & > *:not(:last-child) {
      margin-right: ${spacing.x3};
    }
    ${TicketButton} {
      display: none;
    }
    ${tabletView} {
      ${Logo} {
        display: none;
      }
      ${TicketButton} {
        display: block;
        position: static;
      }
    }
`,
);
export const SublinksInner = styled.div(({ theme: { spacing, colors, header } }) => { 
  return `
  max-width: 1200px;
  margin: auto;
  div {
    display: block;
    span {
      display: block;
      width: 100%;
      white-space: nowrap;
      margin: ${spacing.x1} 0;
    }
  }
  img {
    width: 30%;
    height: auto;
    margin: ${spacing.x2};
  }
  a {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 1.19 !important;
  }
  `
})

export const SubLinks = styled.div(({ theme: { spacing, colors, header } }) => { 
  return `
    position: absolute;
    min-width: 100%;
    top: 100%;
    left: 0;
    background: ${colors.blue};
    padding: ${spacing.x1} ${spacing.x1};
    margin-top: -16px;
    transform: translate(0, -50px);
    opacity: 0;
    pointer-events: none;
    transition: 0.25s;
    transition-delay: 0.2s;
    z-index: -1;
`})

export const LinksWrapper = styled.div(
  ({ theme: { spacing, colors } }) => `
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    ${SubLinks} {
      opacity: 1;
      transform: translate(0,0);
      pointer-events: auto;
      transition-delay: 0s;
    }
  }
  a {
    display: inline-block;
    line-height: 1.14;
    padding-bottom: 0.1em;
    font-weight: 700;
    position: relative;
    font-size: 14px;
    margin: 0 ${spacing.x1};
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      bottom: -0.3em;
      border-bottom: 3px solid ${colors.white};
      left: 0;
      opacity: 0;
      transform: translateY(-0.2em);
      transition: 0.2s;
    }

    &:hover {
      text-decoration: none;

      &:after{
        opacity:1;
        transform: translateY(0);
      }
    }
  }
  `,
);

export const PageLinksWrapper = styled.div(
  ({ theme: { tabletView, spacing, colors } }) => `
    text-transform: uppercase;
    height: 100%;
    display: flex;
    ${tabletView} {
      display: none;
    }
`,
);

export const IconsWrapper = styled.div`
  display: flex;

  button {
    margin-right: 10px;
    &:visible:last-of-type {
      margin-right: 0;
    }
  }
`;

export const MenuButton = styled(IconButton)(
  ({ theme: { tabletView } }) => `
    display: none;
    ${tabletView} {
      display: block;
    }
  `,
);
