import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const Add: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 24 24" {...props}>
    <path fillRule="evenodd" d="M0 0v24h24V0H0zm17.5 13.5H13V18h-2v-4.5H6.5v-2H11V6h2v5.5h4.5v2z" clipRule="evenodd" />
  </StyledSvg>
);

export default Add;
