import styled from 'styled-components';
import { ThemeType } from 'types';
import { HEADER_OFFSET } from 'constants/offsets';

export const Sticky = styled.div(
  ({ theme: { tabletView, mobileView } }: ThemeType) => `
    position: sticky;
    top: ${HEADER_OFFSET.default}px;
    width: 252px;
    ${tabletView} {
      top: ${HEADER_OFFSET.tablet}px;
    }

    ${mobileView} {
      position: relative;
      top: 0;
    }
`,
);

export const StyledSidebar = styled.aside(
  ({ theme: { mobileView, spacing } }: ThemeType) => `
      margin-left: ${spacing.x7};
      z-index: 100;
      width: 252px;
      ${mobileView} {
        padding: 0 ${spacing.x3};        
        margin-left: 0;
        width: 100%;
      }
  `,
);
