import styled from 'styled-components';
import { ThemeType } from 'types';
import FillBlock, { ImageWrapper as FillBlockImageWrapper } from 'blocks/FillBlock/styles';
import { HtmlImage, GatsbyImage, FallbackImage } from 'components/Image/styles';

export default styled(FillBlock)(
  ({
    theme: {
      colors,
      mobileView,
      utils: { rgba },
    },
  }: ThemeType) => `
`,
);

export const ImageWrapper = styled(FillBlockImageWrapper)(
  ({ theme: { colors } }: ThemeType) => `
    ${HtmlImage}, ${GatsbyImage}, ${FallbackImage} {
      position: absolute;
      width: 80%;
      height: 80%;
      top: 50%;
      left: 50%;
      object-fit: contain;
      transform: translate(-50%, -50%);
    }
  `,
);
