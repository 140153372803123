import styled from 'styled-components';
import { ThemeType } from 'types';
import Button, { colors, sizes } from 'components/Button/styles';

interface Props extends ThemeType {
  hasText: boolean;
  reverse: boolean;
  color: string;
  size: string;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
}

export const StyledSpan = styled.span`
  display: inline-block;
  white-space: nowrap;
  margin-top: -0.2em;
`;

export default styled(Button)(({ theme: { iconSizes, spacing, fontFamilies, mobileView, tabletView }, hasText, reverse, size = 'medium', color, hideOnMobile, hideOnTablet }: Props) => {
  const buttonColor = colors[color] || colors.black;
  const buttonSize = sizes[size] || sizes.medium;

  let paddingLeft;
  let paddingRight;
  let iconOnlySize;

  switch (size) {
    case 'small':
      paddingLeft = '12px';
      paddingRight = '14px';
      iconOnlySize = iconSizes.medium;
      break;
    case 'medium':
      paddingLeft = '14px';
      paddingRight = '16px';
      iconOnlySize = iconSizes.large;
      break;
    case 'large':
      paddingLeft = '16px';
      paddingRight = '18px';
      iconOnlySize = iconSizes.large;
      break;
  }

  return `
    display: inline-block;
    position: relative;
    vertical-align: middle;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    appearance: none;
    border: none;
    color: ${buttonColor.color};
    background-color: ${buttonColor.background};
    cursor: pointer;
    font-family: ${fontFamilies.default};
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    ${mobileView} {
      ${hideOnMobile ? 'display: none' : ''}
    }
    ${tabletView} {
      ${hideOnTablet ? 'display: none' : ''}
    }
    > span {
      padding-left: ${paddingLeft};
      padding-right: ${paddingRight};
    }

    svg {
      display: inline-block;
      margin: auto 9px auto auto;
      transition: fill 0.2s ease-in-out;
    }

    * {
      z-index: 2;
      position: relative;
    }

    &:hover {
      color: ${buttonColor.background};
      background-color: ${buttonColor.color};
      svg {
        fill: ${colors.black};
      }
    }

    ${
      hasText
        ? `
      width: auto;
      ${reverse ? 'flex-direction: row-reverse;' : ''}
  
      & > *:not(:last-child) {
        margin-${reverse ? 'left' : 'right'}: ${spacing.x2};
      }

      &:before {
        left: ${reverse ? '-12px' : '-8px'};
        right: ${reverse ? '-8px' : '-12px'};
      }
    `
        : `
      width: ${buttonSize.height};
      
      > span {
        padding: 0;
        width: ${buttonSize.height};
      }

      svg {
        margin: auto;
        width: ${iconOnlySize};
        height: ${iconOnlySize};
      }
    `
    }
`;
});
