import { useEffect, useRef, useState } from 'react';
import isBrowser from 'utils/isBrowser';

export default ({ root = null, rootMargin, threshold = 0 }: IntersectionObserverInit) => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry | null>(null);
  const [node, setNode] = useState(null);

  if (isBrowser()) {
    const observer = useRef(
      new window.IntersectionObserver(([entryToUpdate]) => updateEntry(entryToUpdate), {
        root,
        rootMargin,
        threshold,
      }),
    );

    useEffect(() => {
      const { current: currentObserver } = observer;
      currentObserver.disconnect();

      if (node) {
        currentObserver.observe(node!);
      }

      return () => currentObserver.disconnect();
    }, [node]);
  }

  return [setNode, entry];
};
