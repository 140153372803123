import styled from 'styled-components';
import Slick from 'react-slick';
import { ThemeType, BlockSizeType } from 'types';
import ColumnBlock from 'blocks/Block';
import { HeaderRow, Container } from 'blocks/Block/styles';
import 'slick-carousel/slick/slick.css';
import { HtmlImage, GatsbyImage, FallbackImage } from 'components/Image/styles';
import Title from 'components/Title/styles';
import WhiteTear from 'assets/images/hero-tear-cream.png';
import Button from 'components/Button/styles';

interface SlideType extends ThemeType {
  size: BlockSizeType;
}

interface ImageType extends ThemeType {
  paddingTop: number;
}

const Arrow = styled(Button)(
  ({
    theme: {
      mobileView,
      colors,
      utils: { rgba },
    },
  }: ThemeType) => `
    width: 48px;
    position: absolute;
    z-index: 100;
    cursor: pointer;
    top: 0;
    display: flex;
    align-items: center;

    > span {
      width: 48px;
      box-shadow: 3px 3px 5px ${rgba(colors.eggplant, 0.1)};

      span {
        font-family: sans-serif;
        font-size: 15px;
      }
    }

    ${mobileView} {
      top: auto;
      bottom: 40px;
    }
`,
);

export const NextArrow = styled(Arrow)(
  ({ theme: { mobileView } }: ThemeType) => `
    left: 63px;
    text-align: left;

    > span {
      border-bottom-right-radius: 42px;
      padding: 0;
      padding-right: 13px;

      span {
        transform: translateY(-1px);
      }
      svg{
        height: 10px;
      }
    }

    &:after {
      border-bottom-right-radius: 42px;
    }
`,
);

export const PrevArrow = styled(Arrow)(
  ({ theme: { mobileView } }: ThemeType) => `
    left: 0;
    text-align: right;

    > span {
      border-top-left-radius: 42px;
      padding: 0;
      padding-left: 13px;

      span {
        transform: translateY(1px);
      }
    }

    &:after {
      border-top-left-radius: 42px;
    }
`,
);

export const Slider = styled(Slick)(
  ({ theme: { spacing, mobileView }, size }: SlideType) => `
    display: block;
    position: relative;
    width: 100%;
    padding-top: 86px;
    
    ${mobileView} {
      padding-bottom: 120px;
    }

    .slick-list {
      overflow: visible !important;
    }

    .slick-track {
      ${mobileView} {
        margin-left: 0;
      }
    }

    ${
      size === 'small'
        ? `
          .slick-slide {
            &:nth-of-type(2n) {
              transform: translateY(43px);
            }
          }
          `
        : ''
    }

    ${mobileView} {
      padding-top: 0;
    }
  `,
);

export const Slide = styled.div(
  ({ size, theme: { spacing, mobileView } }: SlideType) => `
    position: relative;
    width: ${size === 'large' ? 390 : 280}px !important;
    margin-right: ${size === 'large' ? spacing.x6 : spacing.x8};

    ${mobileView} {
      width: 80vw !important;
      margin-right: ${size === 'large' ? spacing.x3 : spacing.x4};

      ${ImageContainer} {
        ${mobileView} {
          margin-bottom: ${size === 'large' ? spacing.x2 : spacing.x3};
        }
      }

      ${Title} {
        font-size: 26px;
      }
    }
  `,
);

export const ImageContainer = styled.span(
  ({
    theme: {
      easing,
      colors,
      utils: { rgba },
    },
  }: ImageType) => `
    display: block;
    overflow: visible;
    position: relative;
    height: auto;
    width: 100%;

    ${HtmlImage},
    ${GatsbyImage},
    ${FallbackImage} {
      border-top-left-radius: 80px;
      box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};
      transition: transform 0.4s ${easing}, box-shadow 0.4s ${easing};
    }
  `,
);

export const ContentContainer = styled.span(
  ({
    theme: {
      easing,
      spacing,
      colors,
      utils: { rgba },
    },
  }: ThemeType) => `
    display: block;
    text-decoration: none;
    margin: -32px 15px 0;
    position: relative;
    padding: 14px 16px 20px;
    z-index: 1;
    background-color: ${rgba(colors.eggplant, 0.08)};
    box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};
    transition: transform 0.4s ${easing}, box-shadow 0.4s ${easing};
    color: ${colors.black};
    h3 {
      line-height: 1.2;
      margin-bottom: ${spacing.x3};
    }

    span {
      line-height: 1;
      display: block;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 20px;
      background-color: ${colors.cream};
      z-index: -1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 20px;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(${WhiteTear});
      background-size: 293px 20px;
      z-index: -1;
    }
  `,
);

export const Grid = styled.div(
  ({ theme: { mobileView, tabletView } }: ThemeType) => `
    display: flex;

    ${tabletView} {
      flex-direction: column;
    }

    ${Slide} {
      flex: 0 1 25%;

      h3 {
        line-height: 1.2;
        font-size: 32px;
      }

      span {
        font-size: 14px;
      }

      &:last-of-type {
        margin-right: 0px !important;
      }

      ${tabletView} {
        max-width: 552px;
        width: 100% !important;
        margin-right: 0px !important;

        &:not(:last-of-type) {
          margin-bottom: 64px;
        }

        &:nth-child(even) {
          align-self: flex-end;
        }

        ${ImageContainer} {
          padding: 0;

          a {
            position: relative;
          }
        }
      }

      ${mobileView} {
        max-width: 304px !important;
      }
    }
`,
);

export const CarouselBlock = styled(ColumnBlock)(
  ({ theme: { width, spacing, mobileView } }: ThemeType) => `
    padding-left: 0;
    padding-right: 0;

    ${HeaderRow} {
      padding-left: ${spacing.x6};
      padding-right: ${spacing.x6};

      // Add padding to the width of container to make up for border-box being pushed in.
      max-width: calc(${width.tablet} + ${spacing.x12});

      ${mobileView} {
        padding-left: ${spacing.x3};
        padding-right: ${spacing.x3};
        max-width: calc(${width.tablet} + ${spacing.x6});
      }
    }

    .slick-list {
      overflow: visible !important;
    }

    .slick-track {
      ${mobileView} {
        margin-left: ${spacing.x3};
      }
    }
  `,
);

export const StyledA = styled.a(
  ({
    theme: {
      colors,
      fontFamilies,
      utils: { rgba },
    },
  }: ThemeType) => `
    display: block;
    overflow: visible;
    ${Note} + ${Title}{
      margin-top: 0.5em;
    }
    ${Title} {
      font-family: ${fontFamilies.default};
    }
    &:hover {
      &, * {
        text-decoration: none !important;
      }

      ${ContentContainer} {
        transform: scale(1.008) translate(-5px, -5px);
        box-shadow: 15px 15px 10px ${rgba(colors.eggplant, 0.06)};
      }

      ${HtmlImage},
      ${GatsbyImage},
      ${FallbackImage} {
        transform: scale(1.008) translate(-3px, -3px);
        box-shadow: 12px 12px 9px ${rgba(colors.eggplant, 0.06)};
      }
    }
  `,
);

export const Note = styled.span(
  ({ theme: { colors, fontSizes } }: ThemeType) => `
    color: ${colors.black};
    font-size: ${fontSizes.xxs};
    margin-top: 9px;
`,
);

export const Excerpt = styled.div(
  ({ theme: { spacing } }: ThemeType) => `
    margin-top: ${spacing.x2};
    font-weight: 400;
  `,
);

export default Slider;
