import styled from 'styled-components';
import { ThemeType } from 'types';
import { StyledBlockInner } from 'components/Block/styles';
import Title from 'components/Title/styles';
import Copy from 'components/Copy/styles';
import { HtmlImage, GatsbyImage, FallbackImage } from 'components/Image/styles';
import { BackgroundNoise } from 'layout/components/styles';

export default styled.section(
  ({
    theme: {
      colors,
      mobileView,
      utils: { rgba },
    },
  }: ThemeType) => `
    ${StyledBlockInner} {
      max-width: 1075px;
      display: flex;
      flex-direction: row;
      box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};

      ${mobileView} {
        flex-direction: column;
        padding: 0;
        margin: 0 20px;
      }
    }

    ${Title} {
      margin-bottom: 33px;

      ${mobileView} {
        font-size: 26px;
        line-height: 29px;
        letter-spacing: 1.24px;
      }
    }

    ${Copy} {
      margin-bottom: 38px;
    }

    ${mobileView} {
      margin-bottom: 92px;
    }
`,
);

export const ContentWrapper = styled.div(
  ({ theme: { colors, mobileView } }: ThemeType) => `
    width: 52.558%;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 7.07%;
    padding-right: 10%;

    ${mobileView} {
      display: block;
      width: 100%;
      padding: 30px 20px 42px 23px;
    }
  `,
);

export const ImageWrapper = styled.div(
  ({ theme: { colors, mobileView } }: ThemeType) => `
    position: relative;
    width: 47.442%;
    height: 0;
    padding-top: 47.442%;
    border-top-left-radius: 88px;
    background-color: ${colors.lightGold};
    overflow: hidden;

    ${HtmlImage}, ${GatsbyImage}, ${FallbackImage} {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    ${BackgroundNoise} {
      position: absolute;

      &:after {
        position: absolute;
        background-size: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    ${mobileView} {
      width: 100%;
      border-top-left-radius: 57px;
      padding-top: 100%;
    }
  `,
);
