import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const ArrowDown: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 13 9" {...props}>
    <path fill-rule="evenodd" d="M6.5 9L13 0H0z" clip-rule="evenodd"/>
  </StyledSvg>
);

export default ArrowDown;
