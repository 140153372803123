import React from 'react';
import Image from 'components/Image';
import Block from 'components/Block';
import Title from 'components/Title';
import TitleWrapper from 'components/TitleWrapper';
import Cta from 'components/Cta';
import { StaticQuery, graphql } from 'gatsby';
import StyledRowBlock, { ImagesWrapper, ImageWrapper } from './styles';

export const Social: React.StatelessComponent<Props> = ({ title, ctalink }) => (
  <></>
);

export default Social;
