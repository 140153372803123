import React, { useEffect } from 'react';
import { PostType, ArtistType,} from 'types';
import Slider, { Grid, NextArrow, PrevArrow } from './styles';
import Slide from './Partials/Slide';
import isMobile from 'utils/isMobile';
import { ArrowLeft, ArrowRight } from 'icons';

// Weird bug where I can't use styled(ColumnBlock) inside `/blocks/Block/styles`
export { CarouselBlock } from './styles';
export type CarouselSize = 'small' | 'large';

interface Props {
  items?: PostType[] | ArtistType[];
  news?: PostType[];
  size?: CarouselSize;
  carousel: boolean;
  children?: any;
}

const defaultSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  variableWidth: true,
  slidesToScroll: 3,
  swipeToSlide: true,
  nextArrow: (
    <NextArrow size="medium" color="whitePink" inverted={true}>
      <span>
        <span><ArrowRight /></span>
      </span>
    </NextArrow>
  ),
  prevArrow: (
    <PrevArrow size="medium" color="whitePink" inverted={true}>
      <span>
        <span><ArrowLeft /></span>
      </span>
    </PrevArrow>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToScroll: 1
      }
    },
  ]
};

export const Carousel: React.StatelessComponent<Props> = ({ size = 'large', items, news, carousel = true, children }) => {
  // Adopt the correct item list
  const slides: any = items || news || [];

  // Set default Container type and props
  let Container: any = Slider;
  let containerProps: any = { size };

  // Container is a carousel if exp licitly stated
  if (carousel) {
    containerProps = { ...containerProps, ...defaultSettings, ...{ slidesToScroll: isMobile() ? 1 : 3 } };

    // Hide arrows when 4 or less items on small carousel
    if (!children && size === 'small' && items.length < 4) {
      containerProps = { ...containerProps, arrows: false };
    }
  }
  // Otherwise it is just a Flex Grid
  else {
    Container = Grid;
  }

  // Prevent scrolling up or down the page when we are using the slider
  let firstClientX;
  const touchStart = (e) => {
    const [touch] = e.touches;
    firstClientX = touch.clientX;
  };

  const preventTouch = (e) => {
    const [touch] = e.touches;
    const threshold = 5;

    const clientX = touch.clientX - firstClientX;

    if (Math.abs(clientX) > threshold) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });

    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch);
    };
  }, []);

  return (
    <Container {...containerProps} size={size}>
      {children}
      {!children && slides &&
        slides.map((data, index) => 
            <Slide key={`slide-${index}`} {...data} size={size} />
        )}
    </Container>
  );
};

export default Carousel;
