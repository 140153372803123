import React, { useState } from 'react';
import Collapse from '@kunukn/react-collapse';
import { PageLinkType } from 'types';
import { MenuItem, Link } from './styles';
import isBrowser from 'utils/isBrowser';

const Item: React.StatelessComponent<any> = ({ label: title, url, childItems: { nodes: sublinks } }) => {
  if (!isBrowser()) {
    return null;
  }

  const [showAnswer, toggleAnswer] = useState(false);

  return (
    <MenuItem>
      <Link href={url} hasSublinks={(sublinks && sublinks.length > 0)}
      open={showAnswer}
      onClick={(e) => {
        if(sublinks && sublinks.length > 0){
          e.preventDefault();
          toggleAnswer(!showAnswer);
        }
      }}
      >
        <span className="h4 main-link">{title}</span>
      </Link>
      {sublinks && (
        <Collapse isOpen={showAnswer} className="sublinks">
          {sublinks.map((sublink: PageLinkType, sublinkIndex) => (
            <Link key={sublinkIndex} href={sublink.url}>
              <span className="h7">{sublink.label}</span>
            </Link>
          ))}
        </Collapse>
      )}
    </MenuItem>
  );
};

export default Item;
