import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const SearchIcon: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 24 24" {...props}>
    <path
      d="M15.1,15a7.07,7.07,0,1,0-2.28,1.55A7.16,7.16,0,0,0,15,15.1a1990328647664.26,1990328647664.26,0,0,0,.1-.1Zm2,.72,5.58,5.57a1,1,0,0,1-1.42,1.42l-5.57-5.58a9.05,9.05,0,1,1,1.41-1.41Z"
      fillRule="evenodd"
    />
  </StyledSvg>
);

export default SearchIcon;
