import styled from 'styled-components';

export default styled.div(
  ({ theme: { spacing, tabletView, mobileView } }) => `
  & > *:not(:last-child) {
    margin-right: ${spacing.x4};
  }

  ${tabletView} {
    & > *:not(:last-child) {
      margin-right: ${spacing.x3};
    }
  }

  ${mobileView} {
    & > *:not(:last-child) {
      margin-right: ${spacing.x2};
    }
  }
`,
);
