import React from 'react';
import Video from 'components/Video';
import Title from 'components/Title';
import Block from 'components/Block';
import StyledVideoBlock from './styles';

interface Props {
  title: string;
  embed?: string;
}

export const VideoBlock: React.StatelessComponent<Props> = ({ title, embed }) => (
  <StyledVideoBlock>
    <Block>
      <Title>{title}</Title>
      <Video url={embed} />
    </Block>
  </StyledVideoBlock>
);

export default VideoBlock;
