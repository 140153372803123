import { useEffect } from 'react';

export default (targetKey, handleKeyPress) => {
  if (!handleKeyPress) return;

  const downHandler = ({ key }) => {
    if (key === targetKey) {
      handleKeyPress();
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []);
};
