import styled from 'styled-components';
import { ThemeType } from 'types';
import StyledFillBlock from 'blocks/FillBlock/styles';

export default styled(StyledFillBlock)(
  ({
    theme: {
      colors,
      mobileView,
      utils: { rgba },
    },
  }: ThemeType) => `
`,
);
