import React from 'react';
import { PostType, ColumnBlockType } from 'types';
import { distanceInWordsToNow } from 'date-fns';
import StyledFeaturedNewsBlock, { Note, NewsItem, Reference, NewsWrapper } from './styles';
import Block from 'components/Block';
import Title from 'components/Title';
import TitleWrapper from 'components/TitleWrapper';
import Cta from 'components/Cta';
import { ArrowRight } from 'icons';
import isMobile from 'utils/isMobile';

interface Props extends ColumnBlockType {
  posts?: PostType[];
}

export const NewsSnippets: React.StatelessComponent<Props> = ({ title, ctalink, posts }) => {

  return (
    <StyledFeaturedNewsBlock>
      <Block>
        <TitleWrapper>
          <Title>{title}</Title>
          {!isMobile() && <Cta {...ctalink} />}
        </TitleWrapper>
        {posts && 
          <NewsWrapper>
          {posts.map(({title, date, link, outLink, postOptions}, index) =>
            <NewsItem key={`news-${index}`} href={outLink && outLink.url ? outLink.url : link} target={outLink && outLink.url && '_blank'}>
              {date &&
                <Note>{distanceInWordsToNow(date, { addSuffix: true })}</Note>
              }
              <h4 className="h6" dangerouslySetInnerHTML={{ __html: title }} />
              {postOptions && postOptions.reference && <Reference>{postOptions.reference} <ArrowRight /></Reference>}
            </NewsItem>
          )}
          </NewsWrapper>
        }
        {isMobile() && <Cta {...ctalink} />}
      </Block>
    </StyledFeaturedNewsBlock>
  );
};

export default NewsSnippets;
