import React from 'react';
import { format } from 'date-fns';
import { SessionType } from 'types';
import { Slide as SlideWrapper, ImageContainer, ContentContainer, StyledA, Note } from '../styles';
import Card from 'components/Card';

export const Slide: React.StatelessComponent<Props> = (Props) => {
  const { data, size } = Props;
  let foundSession: SessionType;
  if (data && data.sessions && data.sessions.length) {
    foundSession = data.sessions.find(({ sessionDate }) => sessionDate);

    if (foundSession) {
      foundSession = {
        ...foundSession,
        sessionDate: format(foundSession.sessionDate, 'dddd'),
      };
    }
  }
  return (
    <SlideWrapper size={size}>
      <Card {...Props} />
    </SlideWrapper>
  )
}

export default Slide;
