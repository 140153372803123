import { useEffect, useState } from 'react';
import theme from '../styles/theme';
import isBrowser from 'utils/isBrowser';

export default () => {
  if (!isBrowser()) {
    return null;
  }
  return window.innerWidth && window.innerWidth <= theme.screenWidths.mobile;
};

export const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return width;
};
