import React from 'react';
import Cta from 'components/Cta';

import { ColumnBlockType, RowBlockType, LinkType } from 'types';
import getSafe from 'utils/getSafe';
import Block, { Column, Container, FlexContainer, HeaderRow, LinkWrapper, Title, TitleWrapper } from './styles';

interface Props {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  ctaStyling?: boolean;
  titleColor?: string;
  ctalink?: any;
}

const blockStyle = {
  small: {
    title: 'h3',
    subtitle: 'h7',
  },
  medium: {
    title: 'h1',
    subtitle: 'h5',
  },
  large: {
    title: 'h0',
    subtitle: 'h4',
  },
};

export const RowBlock: React.FunctionComponent<RowBlockType & Props> = ({
  title,
  subTitle,
  cta,
  operations,
  type = 'medium',
  children,
  className,
  ctaStyling,
  titleColor,
}) => {
  const hasCta = cta && getSafe(() => cta.url);

  return (
    <Block hasLink={hasCta || ctaStyling} className={className}>
      <FlexContainer>
        <Column>
          {title && (
            <Title color={titleColor} type={type} className={blockStyle[type].title}>
              {title}
            </Title>
          )}
          {subTitle && <h3 className={blockStyle[type].subtitle}>{subTitle}</h3>}
          {cta && hasCta && <Cta {...cta} />}
          {operations && operations}
        </Column>
        {children && <Column>{children}</Column>}
      </FlexContainer>
    </Block>
  );
};

export const ColumnBlock: React.StatelessComponent<ColumnBlockType & Props> = ({
  id,
  title,
  subTitle,
  ctalink,
  children,
  className,
  ctaStyling,
  titleColor,
  ...other
}) => {
  const hasCta = ctalink && getSafe(() => ctalink.url);

  return (
    <Block hasLink={hasCta || ctaStyling} className={className} id={id} {...other}>
      {title && (
        <HeaderRow hasLink={hasCta || ctaStyling} type={`large`}>
          <TitleWrapper>
            <Title color={titleColor} type={`large`} className={blockStyle['large'].title}>
              {title}
            </Title>
            {subTitle && <h3 className={blockStyle[`large`].subtitle}>{subTitle}</h3>}
          </TitleWrapper>
          {ctalink && hasCta && <Cta {...ctalink} />}
        </HeaderRow>
      )}
      {children && <Container>{children}</Container>}
    </Block>
  );
};

export default ColumnBlock;
