import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const ArrowRight: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 9 14" {...props}>
    <path fill-rule="evenodd" d="M9 7L0 .5v13z" clip-rule="evenodd"/>
  </StyledSvg>
);

export default ArrowRight;
