export const PAGE_WRAP_ID = 'page-wrap';

export const MENUS = {
  MAIN: 'main',
  SEARCH: 'search',
  SUBSCRIBE: 'subscribe',
};

export const MODALS = {
  MYSPLENDOUR: 'mysplendour',
};
