import styled from 'styled-components';
import { HeaderContent } from '../styles';
import { ThemeType } from 'types';

export const StyledPrimaryHeader = styled.header(
  ({ theme: {  colors, tabletView } }: ThemeType) => `
    width: 100%;
    background-color: ${colors.pink};
    color: ${colors.black};
    position: relative;
    z-index: 300;
    transition: margin-top 0.3s ease-in-out 0.3s;
    display: none;
    height: 36px;
    ${tabletView} {
      display:block;
    }

    ${HeaderContent} {
      height: 100%;
    }
  `,
);
