import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const ToTopIcon: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 24 24" {...props}>
    <path
      d="M12,21.75A9.75,9.75,0,1,0,2.25,12,9.76,9.76,0,0,0,12,21.75Zm0,1.5A11.25,11.25,0,1,1,23.25,12,11.25,11.25,0,0,1,12,23.25Zm.75-13.94V16.5a.75.75,0,0,1-1.5,0V9.31L8.78,11.78a.75.75,0,0,1-1.06-1.06L11.47,7a.75.75,0,0,1,1.06,0l3.75,3.75a.75.75,0,0,1-1.06,1.06Z"
      fillRule="evenodd"
    />
  </StyledSvg>
);

export default ToTopIcon;
