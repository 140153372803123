import React from 'react';
import { SvgIconType } from '../types';
import StyledSvg from './styles';

const AppleMusicIcon: React.StatelessComponent<SvgIconType> = (props) => (
  <StyledSvg viewBox="0 0 82 96" {...props}>
    <path d="M54.35,15.58c3.13-4,5.5-9.75,4.65-15.58C53.88.36,47.9,3.63,44.41,7.9c-3.18,3.86-5.8,9.62-4.78,15.2C45.23,23.27,51,19.92,54.35,15.58Z" />
    <path d="M79.22,32.21C74.31,26,67.4,22.47,60.88,22.47c-8.61,0-12.25,4.12-18.23,4.12-6.17,0-10.86-4.11-18.3-4.11S9.24,27,4.31,34.6c-6.94,10.76-5.76,31,5.49,48.24,4,6.17,9.4,13.1,16.43,13.16,6.26.06,8-4,16.5-4.06s10.09,4.11,16.34,4c7,0,12.71-7.74,16.73-13.91A69.76,69.76,0,0,0,82,70.43C65.72,64.24,63.11,41.09,79.22,32.21Z" />
  </StyledSvg>
);

export default AppleMusicIcon;
